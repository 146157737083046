import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Calendar as CalendarIcon, Clock, AlertCircle, CheckCircle, XCircle, User, Scissors, DollarSign } from 'lucide-react';

const AppointmentCalendar = ({ appointments = [], onDaySelect }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarDays, setCalendarDays] = useState([]);
  const [selectedDayAppointments, setSelectedDayAppointments] = useState([]);

  // Filter out deleted appointments
  const filteredAppointments = appointments.filter(appointment => appointment.status !== 'deleted');

  // Status config with color mapping
  const statusConfig = {
    pending: { 
      label: 'Beklemede', 
      bgColor: 'bg-yellow-100', 
      textColor: 'text-yellow-800',
      icon: AlertCircle 
    },
    approved: { 
      label: 'Onaylandı', 
      bgColor: 'bg-blue-100', 
      textColor: 'text-blue-800',
      icon: CheckCircle 
    },
    canceled: { 
      label: 'İptal Edildi', 
      bgColor: 'bg-red-100', 
      textColor: 'text-red-800',
      icon: XCircle 
    },
    completed: { 
      label: 'Tamamlandı', 
      bgColor: 'bg-green-100', 
      textColor: 'text-green-800',
      icon: CheckCircle 
    },
    deleted: { 
      label: 'Silindi', 
      bgColor: 'bg-gray-100', 
      textColor: 'text-gray-800',
      icon: XCircle 
    }
  };

  // Month names in Turkish
  const monthNames = [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
  ];

  // Day names in Turkish
  const dayNames = ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Pzr"];

  // Function to get days in month for calendar
  const getDaysInMonth = (year, month) => {
    // Get first day of month
    const firstDay = new Date(year, month, 1);
    let firstDayIndex = firstDay.getDay();
    // In JS, Sunday is 0, but we want Monday as 0
    firstDayIndex = firstDayIndex === 0 ? 6 : firstDayIndex - 1;
    
    // Get days in month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    
    // Get days in previous month
    const prevMonthDays = new Date(year, month, 0).getDate();
    
    const days = [];
    
    // Add previous month days
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      days.push({
        day: prevMonthDays - i,
        month: month - 1,
        year: month === 0 ? year - 1 : year,
        isCurrentMonth: false
      });
    }
    
    // Add current month days
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        day: i,
        month,
        year,
        isCurrentMonth: true
      });
    }
    
    // Add next month days
    const remainingDays = 42 - days.length; // 6 rows of 7 days
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        day: i,
        month: month + 1,
        year: month === 11 ? year + 1 : year,
        isCurrentMonth: false
      });
    }
    
    return days;
  };

  // Update calendar days when month changes
  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    setCalendarDays(getDaysInMonth(year, month));
  }, [currentDate]);

  // Handle month navigation
  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    setSelectedDate(null);
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    setSelectedDate(null);
  };

  // Format date to YYYY-MM-DD
  const formatDate = (year, month, day) => {
    return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  // Check if a day has appointments
  const hasAppointments = (day) => {
    if (!filteredAppointments || filteredAppointments.length === 0) return false;
    
    try {
      const dateStr = formatDate(day.year, day.month, day.day);
      return filteredAppointments.some(appointment => 
        appointment.date === dateStr && 
        appointment.status !== 'canceled'
      );
    } catch (error) {
      console.error("Error checking appointments for day:", error);
      return false;
    }
  };

  // Get appointment count by status for a specific day
  const getAppointmentCountByStatus = (day, status) => {
    if (!filteredAppointments || filteredAppointments.length === 0) return 0;
    
    try {
      const dateStr = formatDate(day.year, day.month, day.day);
      return filteredAppointments.filter(
        appointment => appointment.date === dateStr && appointment.status === status
      ).length;
    } catch (error) {
      console.error("Error counting appointments by status:", error);
      return 0;
    }
  };

  // Handle day selection
  const handleDayClick = (day) => {
    try {
      const dateStr = formatDate(day.year, day.month, day.day);
      setSelectedDate(dateStr);
      
      // Filter appointments for the selected day (excluding deleted ones)
      const dayAppointments = filteredAppointments.filter(
        appointment => appointment.date === dateStr
      );
      
      setSelectedDayAppointments(dayAppointments);
      
      if (onDaySelect) {
        onDaySelect(dateStr, dayAppointments);
      }
    } catch (error) {
      console.error("Error handling day click:", error);
    }
  };

  // Get today's date for highlighting
  const today = new Date();
  const todayStr = formatDate(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  // Navigate to current month
  const goToToday = () => {
    setCurrentDate(new Date());
    
    // Select today
    const today = new Date();
    const dateStr = formatDate(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    
    handleDayClick({
      day: today.getDate(),
      month: today.getMonth(),
      year: today.getFullYear(),
      isCurrentMonth: true
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      {/* Calendar header */}
      <div className="p-4 bg-gradient-to-r from-blue-500 to-cyan-500 text-white">
        <div className="flex items-center justify-between">
          <button 
            onClick={prevMonth}
            className="p-1 rounded-full hover:bg-blue-600 transition-colors"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold">
              {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
            </h3>
            <button 
              onClick={goToToday}
              className="text-xs bg-white text-blue-600 px-2 py-0.5 rounded mt-1 hover:bg-blue-50"
            >
              Bugüne Git
            </button>
          </div>
          
          <button 
            onClick={nextMonth}
            className="p-1 rounded-full hover:bg-blue-600 transition-colors"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
      </div>
      
      {/* Calendar grid */}
      <div className="p-4">
        {/* Day headers */}
        <div className="grid grid-cols-7 gap-1 mb-2">
          {dayNames.map((day, index) => (
            <div key={index} className="text-center py-1 text-sm font-medium text-gray-500">
              {day}
            </div>
          ))}
        </div>
        
        {/* Calendar days */}
        <div className="grid grid-cols-7 gap-1">
          {calendarDays.map((day, index) => {
            const dateStr = formatDate(day.year, day.month, day.day);
            const isToday = dateStr === todayStr;
            const isSelected = dateStr === selectedDate;
            const hasAppts = hasAppointments(day);
            
            // Count appointments by status
            const pendingCount = getAppointmentCountByStatus(day, 'pending');
            const approvedCount = getAppointmentCountByStatus(day, 'approved');
            const completedCount = getAppointmentCountByStatus(day, 'completed');
            
            return (
              <button
                key={index}
                onClick={() => handleDayClick(day)}
                className={`
                  h-12 flex flex-col items-center justify-center rounded-md text-sm
                  ${!day.isCurrentMonth ? 'text-gray-400' : 'text-gray-800'} 
                  ${isToday ? 'bg-blue-100 font-bold' : ''}
                  ${isSelected ? 'bg-blue-500 text-white' : ''}
                  ${hasAppts && !isSelected ? 'font-bold' : ''}
                  hover:bg-blue-100 hover:text-blue-700 transition-colors
                `}
              >
                <span>{day.day}</span>
                {hasAppts && (
                  <div className="flex space-x-1 mt-0.5">
                    {pendingCount > 0 && (
                      <div className={`w-1 h-1 rounded-full ${isSelected ? 'bg-white' : 'bg-yellow-500'}`}></div>
                    )}
                    {approvedCount > 0 && (
                      <div className={`w-1 h-1 rounded-full ${isSelected ? 'bg-white' : 'bg-blue-500'}`}></div>
                    )}
                    {completedCount > 0 && (
                      <div className={`w-1 h-1 rounded-full ${isSelected ? 'bg-white' : 'bg-green-500'}`}></div>
                    )}
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AppointmentCalendar; 