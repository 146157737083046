import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { 
  Calendar, 
  DollarSign, 
  Percent, 
  Search, 
  CreditCard,
  User,
  Filter,
  FileSpreadsheet, // Excel icon
  FileText, // PDF icon
  TrendingUp,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function Report() {
  const navigate = useNavigate();
  const [totalCosts, setTotalCosts] = useState(0);
  const [netProfit, setNetProfit] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Son 30 günün tarih aralığını hesaplayan fonksiyon
  const getDefaultDateRange = () => {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - 30);
    
    return {
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    };
  };

  // Başlangıç değerleri ile state'leri oluştur
  const defaultDates = getDefaultDateRange();
  const [startDate, setStartDate] = useState(defaultDates.start);
  const [endDate, setEndDate] = useState(defaultDates.end);
  const [allBills, setAllBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [allCosts, setAllCosts] = useState([]);
  const [filteredCosts, setFilteredCosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [customers, setCustomers] = useState([]);

  // Tüm verileri çek
const fetchAllData = async () => {
  try {
    const [customerSnapshot, billsSnapshot, costsSnapshot] = await Promise.all([
      getDocs(query(collection(db, "users"), where("role", "==", "customer"))),
      getDocs(query(
        collection(db, "bills"),
        where("status", "==", "active")
      )),
      getDocs(query(
        collection(db, "costs"),
        where("status", "==", "active")
      ))
    ]);

    const customersData = customerSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCustomers(customersData);

    // Son 30 günlük tarih aralığını al
    const last30Days = getLast30Days();

    const fetchedBills = billsSnapshot.docs
      .map((doc) => {
        const billData = doc.data();
        const customer = customersData.find((c) => c.id === billData.customerId);

        return {
          id: doc.id,
          ...billData,
          customerName: customer ? `${customer.name} ${customer.surname}` : "Bilinmiyor",
          type: 'bill',
          createdAt: billData.createdAt?.toDate() || new Date() // Handle missing createdAt
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt) // Sort in memory instead
      .filter(bill => {
        const billDate = bill.date;
        return billDate >= startDate && billDate <= endDate;
      });

    const fetchedCosts = costsSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
        type: 'cost',
        createdAt: doc.data().createdAt?.toDate() || new Date(), // Handle missing createdAt
        categoryName: doc.data().categoryName || '', // Include category name
        subCategory: doc.data().subCategory || '' // Include subcategory
      }))
      .sort((a, b) => b.createdAt - a.createdAt) // Sort in memory instead
      .filter(cost => {
        const costDate = cost.date;
        return costDate >= startDate && costDate <= endDate;
      });

    // Son 30 günlük giderleri hesapla
    const last30DaysCosts = costsSnapshot.docs
      .map(doc => doc.data())
      .filter(cost => cost.date >= last30Days.start && cost.date <= last30Days.end)
      .reduce((sum, cost) => sum + Number(cost.price || 0), 0);

    // Son 30 günlük gelirleri hesapla
    const last30DaysIncome = billsSnapshot.docs
      .map(doc => doc.data())
      .filter(bill => bill.date >= last30Days.start && bill.date <= last30Days.end)
      .reduce((sum, bill) => sum + Number(bill.totalPrice || 0), 0);

    // Net karı hesapla
    const netProfit = last30DaysIncome - last30DaysCosts;

    setTotalCosts(last30DaysCosts);
    setNetProfit(netProfit);
    setAllBills(fetchedBills);
    setFilteredBills(fetchedBills);
    setAllCosts(fetchedCosts);
    setFilteredCosts(fetchedCosts);
    updateTotals(fetchedBills, fetchedCosts);
  } catch (error) {
    console.error("Veri çekme hatası:", error);
  }
};

  // Toplamları güncelle
  const updateTotals = (bills, costs) => {
    // Gelir hesaplama
    const income = bills.reduce((sum, bill) => sum + Number(bill.totalPrice || 0), 0);
    // İndirim hesaplama
    const discount = bills.reduce((sum, bill) => sum + Number(bill.discount || 0), 0);
    // Gider hesaplama
    const totalCosts = costs.reduce((sum, cost) => sum + Number(cost.price || 0), 0);
    // Net kar hesaplama
    const netProfit = income - totalCosts;
    
    setTotalIncome(income);
    setTotalDiscount(discount);
    setTotalCosts(totalCosts);
    setNetProfit(netProfit);
  };

  // İlk yükleme ve tarih değişimlerinde verileri çek
  useEffect(() => {
    fetchAllData();
  }, [startDate, endDate]);

  // Filtreleme fonksiyonu
  const applyFilters = () => {
    let filteredBillsResult = [...allBills];
    let filteredCostsResult = [...allCosts];

    // Tarih filtresi
    if (startDate && endDate) {
      filteredBillsResult = filteredBillsResult.filter(bill => {
        const billDate = bill.date;
        return billDate >= startDate && billDate <= endDate;
      });
      filteredCostsResult = filteredCostsResult.filter(cost => {
        const costDate = cost.date;
        return costDate >= startDate && costDate <= endDate;
      });
    }

    // İsim ve gider kategorisi arama filtresi
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase().trim();
      filteredBillsResult = filteredBillsResult.filter(bill =>
        bill.customerName.toLowerCase().includes(searchLower)
      );
      filteredCostsResult = filteredCostsResult.filter(cost =>
        (cost.categoryName?.toLowerCase()?.includes(searchLower) || 
         cost.subCategory?.toLowerCase()?.includes(searchLower))
      );
    }

    // Ödeme türü filtresi
    if (selectedPaymentType) {
      filteredBillsResult = filteredBillsResult.filter(bill => 
        bill.paymentType === selectedPaymentType
      );
      filteredCostsResult = filteredCostsResult.filter(cost => 
        cost.paymentType === selectedPaymentType
      );
    }

    setFilteredBills(filteredBillsResult);
    setFilteredCosts(filteredCostsResult);
    updateTotals(filteredBillsResult, filteredCostsResult);
  };

  // Filtreleri sıfırla
  const resetFilters = () => {
    const defaultDates = getDefaultDateRange();
    setStartDate(defaultDates.start);
    setEndDate(defaultDates.end);
    setSearchTerm("");
    setSelectedPaymentType("");
  };

  // Herhangi bir filtre değiştiğinde filtrelemeyi uygula
  useEffect(() => {
    applyFilters();
  }, [searchTerm, selectedPaymentType])

// Excel export fonksiyonu
const exportToExcel = () => {
  // Önce özet verileri
  const summaryData = [
    ['Adisyon Raporu'],
    [`${startDate} - ${endDate}`],
    [''],
    [`Toplam Gelir: ${totalIncome.toLocaleString('tr-TR')} TL`],
    [`Toplam İndirim: ${totalDiscount.toLocaleString('tr-TR')} TL`],
    [''] // Boş satır ekleyerek tablo ile özet arasında boşluk bırakıyoruz
  ];

  // Sonra tablo verileri
  const tableData = filteredBills.map(bill => ({
    'Tarih': new Date(bill.date).toLocaleDateString('tr-TR'),
    'Müşteri': bill.customerName,
    'Fiyat': `${bill.totalPrice.toLocaleString('tr-TR')} TL`,
    'İndirim': `${(bill.discount || 0).toLocaleString('tr-TR')} TL`,
    'Ödeme Türü': bill.paymentType
  }));

  // Özet kısmı için worksheet oluştur
  const ws = XLSX.utils.aoa_to_sheet(summaryData);

  // Tablo verilerini özet kısmının altına ekle
  XLSX.utils.sheet_add_json(ws, tableData, {
    origin: `A${summaryData.length + 1}`, // Özet verilerinin altından başla
    skipHeader: false
  });

  // Excel workbook oluştur ve kaydet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Adisyonlar");
  
  // Excel dosyasını indir
  XLSX.writeFile(wb, `Adisyon_Raporu_${startDate}_${endDate}.xlsx`);
};

// PDF export fonksiyonu
const exportToPDF = () => {
  // PDF dosyası oluştur ve Türkçe karakter desteği için font ekle
  const doc = new jsPDF();
  
  // Türkçe karakter desteği için font ekleme
  doc.setFont("helvetica");
  
  // Başlık
  doc.setFontSize(16);
  doc.text("Adisyon Raporu", 14, 15);
  
  // Tarih aralığı
  doc.setFontSize(10);
  doc.text(`${startDate} - ${endDate}`, 14, 25);

  // Özet bilgiler
  doc.setFontSize(12);
  doc.text(`Toplam Gelir: ${totalIncome.toLocaleString('tr-TR')} TL`, 14, 35);
  doc.text(`Toplam İndirim: ${totalDiscount.toLocaleString('tr-TR')} TL`, 14, 42);

  // Tablo verileri
  const tableData = filteredBills.map(bill => [
    new Date(bill.date).toLocaleDateString('tr-TR'),
    bill.customerName,
    `${bill.totalPrice.toLocaleString('tr-TR')} TL`,
    `${(bill.discount || 0).toLocaleString('tr-TR')} TL`,
    bill.paymentType
  ]);

  // Tablo başlıkları
  const headers = [['Tarih', 'Müşteri', 'Fiyat', 'İndirim', 'Ödeme Türü']];

  // Tablo oluştur
  doc.autoTable({
    head: headers,
    body: tableData,
    startY: 50,
    styles: { 
      font: "helvetica",
      fontSize: 8, 
      cellPadding: 2
    },
    headStyles: { 
      fillColor: [41, 128, 185], 
      textColor: 255,
      fontSize: 9
    }
  });

  // PDF dosyasını indir
  doc.save(`Adisyon_Raporu_${startDate}_${endDate}.pdf`);
};

// Son 30 gün için tarih hesaplama fonksiyonu
const getLast30Days = () => {
  const end = new Date();
  const start = new Date();
  start.setDate(end.getDate() - 30);
  
  return {
    start: start.toISOString().split('T')[0],
    end: end.toISOString().split('T')[0]
  };
};


  return (
    <div className="p-6">
      {/* Filtreler */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center gap-2">
          <Filter className="h-5 w-5 text-blue-500" />
          Filtreler
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Tarih Filtreleri */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Başlangıç Tarihi
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Bitiş Tarihi
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Müşteri Arama */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Müşteri/Kategori Ara
            </label>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              <input
                type="text"
                placeholder="İsim veya gider kategorisi..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Ödeme Türü */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Ödeme Türü
            </label>
            <select
              value={selectedPaymentType}
              onChange={(e) => setSelectedPaymentType(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Tümü</option>
              <option value="Nakit">Nakit</option>
              <option value="Kredi Kartı">Kredi Kartı</option>
              <option value="Havale">Havale</option>
              <option value="Açık Hesap">Açık Hesap</option>
              <option value="Açık Hesap Ödeme">Açık Hesap Ödeme</option>
            </select>
          </div>
        </div>

{/* Filtre ve Export Butonları */}
<div className="flex justify-between items-center gap-4 mt-6">
  {/* Sol taraftaki filtre butonları */}
  <div className="flex gap-4">
    <button
      onClick={applyFilters}
      className="flex items-center justify-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all"
    >
      <Filter className="h-4 w-4" />
      Filtrele
    </button>
    <button
      onClick={resetFilters}
      className="flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-all"
    >
      Filtreleri Sıfırla
    </button>
  </div>
  
  {/* Sağ taraftaki export butonları */}
  <div className="flex gap-4">
    <button
      onClick={exportToExcel}
      className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-all"
    >
      <FileSpreadsheet className="h-4 w-4" />
      Excel'e Aktar
    </button>
    <button
      onClick={exportToPDF}
      className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-all"
    >
      <FileText className="h-4 w-4" />
      PDF'e Aktar
    </button>
  </div>
</div>
</div>

{/* Özet Kartları */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
    {/* Gelir ve İndirim Kartı */}
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="grid grid-cols-2 gap-4">
        {/* Gelir Bölümü */}
        <div className="flex items-center gap-4">
          <div className="p-3 bg-green-100 rounded-full">
            <DollarSign className="h-6 w-6 text-green-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Toplam Gelir</h3>
            <p className="text-2xl font-semibold text-gray-900">{totalIncome.toLocaleString('tr-TR')} TL</p>
          </div>
        </div>
        
        {/* İndirim Bölümü */}
        <div className="flex items-center gap-4">
          <div className="p-3 bg-orange-100 rounded-full">
            <Percent className="h-6 w-6 text-orange-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Toplam İndirim</h3>
            <p className="text-2xl font-semibold text-gray-900">{totalDiscount.toLocaleString('tr-TR')} TL</p>
          </div>
        </div>
      </div>
    </div>

    {/* Gider ve Net Kar Kartı */}
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="grid grid-cols-2 gap-4">
        {/* Gider Bölümü */}
        <div className="flex items-center gap-4">
          <div className="p-3 bg-red-100 rounded-full">
            <DollarSign className="h-6 w-6 text-red-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Toplam Gider</h3>
            <p className="text-2xl font-semibold text-gray-900">{totalCosts.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</p>
          </div>
        </div>

        {/* Net Kar Bölümü */}
        <div className="flex items-center gap-4">
          <div className="p-3 bg-blue-100 rounded-full">
            <TrendingUp className="h-6 w-6 text-blue-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Net Kar</h3>
            <p className="text-2xl font-semibold text-gray-900">{netProfit.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</p>
          </div>
        </div>
      </div>
    </div>
  </div>

      {/* Adisyon ve Gider Tablosu */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tarih
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tür
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Müşteri/Kategori
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fiyat
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  İndirim
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ödeme Türü
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[...filteredBills, ...filteredCosts]
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((item) => (
                <tr key={item.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(item.date).toLocaleDateString('tr-TR')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      item.type === 'bill' 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {item.type === 'bill' ? 'Adisyon' : 'Gider'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4 text-gray-500" />
                      {item.type === 'bill' ? item.customerName : `${item.categoryName || ''} ${item.subCategory ? '/ ' + item.subCategory : ''}`}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.type === 'bill' ? (
                      item.discount > 0 ? (
                        <div className="flex items-center gap-2">
                          <span className="line-through text-gray-500">
                            {item.price.toLocaleString('tr-TR')} TL
                          </span>
                          <span className="text-green-600 font-medium">
                            {item.totalPrice.toLocaleString('tr-TR')} TL
                          </span>
                        </div>
                      ) : (
                        <span className="text-green-600 font-medium">
                          {item.totalPrice.toLocaleString('tr-TR')} TL
                        </span>
                      )
                    ) : (
                      <span className="text-red-600 font-medium">
                        {item.price.toLocaleString('tr-TR')} TL
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-red-600 font-medium">
                    {item.type === 'bill' ? (item.discount || 0).toLocaleString('tr-TR') + ' TL' : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <CreditCard className="h-4 w-4 text-gray-500" />
                      {item.paymentType}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200 bg-white">
          <div className="flex items-center">
            <span className="text-sm text-gray-700">
              Sayfa başına gösterim: 
              <select
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page when changing items per page
                }}
                className="ml-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </span>
          </div>

          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-700">
              <span className="font-medium">{Math.min((currentPage - 1) * itemsPerPage + 1, filteredBills.length + filteredCosts.length)}</span>
              {' '}-{' '}
              <span className="font-medium">{Math.min(currentPage * itemsPerPage, filteredBills.length + filteredCosts.length)}</span>
              {' '}/ {' '}
              <span className="font-medium">{filteredBills.length + filteredCosts.length}</span>
            </span>

            <nav className="flex items-center gap-2">
              <button
                onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
                disabled={currentPage === 1}
                className={`p-2 rounded-md ${
                  currentPage === 1
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                <ChevronLeft className="h-5 w-5" />
              </button>

              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {Array.from({ length: Math.min(5, Math.ceil((filteredBills.length + filteredCosts.length) / itemsPerPage)) }, (_, i) => (
                  <button
                    key={i + 1}
                    onClick={() => setCurrentPage(i + 1)}
                    className={`px-3 py-1 rounded-md ${
                      currentPage === i + 1
                        ? 'bg-blue-500 text-white'
                        : 'text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>

              <button
                onClick={() => setCurrentPage(page => Math.min(Math.ceil((filteredBills.length + filteredCosts.length) / itemsPerPage), page + 1))}
                disabled={currentPage >= Math.ceil((filteredBills.length + filteredCosts.length) / itemsPerPage)}
                className={`p-2 rounded-md ${
                  currentPage >= Math.ceil((filteredBills.length + filteredCosts.length) / itemsPerPage)
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;