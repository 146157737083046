import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  Calendar, 
  DollarSign, 
  Users, 
  UserCircle, 
  Filter,
  TrendingUp,
  Award,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

const Bests = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(getDefaultDateRange().start);
  const [endDate, setEndDate] = useState(getDefaultDateRange().end);
  const [topServices, setTopServices] = useState([]);
  const [topEmployees, setTopEmployees] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Helper function to get default date range (last 7 days)
  function getDefaultDateRange() {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - 6); // -6 because we want to include today
    
    return {
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    };
  }

  // Fetch users data
  const fetchUsers = async () => {
    try {
      // Fetch customers and employees in parallel
      const [customerSnapshot, employeeSnapshot] = await Promise.all([
        getDocs(query(collection(db, "users"), where("role", "==", "customer"))),
        getDocs(query(collection(db, "users"), where("role", "==", "employee")))
      ]);

      const customersData = customerSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCustomers(customersData);

      const employeesData = employeeSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEmployees(employeesData);

      return { customersData, employeesData };
    } catch (error) {
      console.error("Error fetching users:", error);
      return null;
    }
  };

  const fetchData = async (customersData, employeesData) => {
    if (!startDate || !endDate) return;

    try {
      const billsRef = collection(db, 'bills');
      const q = query(
        billsRef,
        where('date', '>=', startDate),
        where('date', '<=', endDate)
      );

      const querySnapshot = await getDocs(q);
      const bills = [];
      querySnapshot.forEach((doc) => {
        bills.push({ id: doc.id, ...doc.data() });
      });

      // Process top services
      const servicesMap = new Map();
      bills.forEach(bill => {
        bill.services.forEach(service => {
          const count = servicesMap.get(service.name) || 0;
          servicesMap.set(service.name, count + 1);
        });
      });
      const sortedServices = Array.from(servicesMap.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([name, count]) => ({ name, count }));
      setTopServices(sortedServices);

      // Process top employees
      const employeesMap = new Map();
      bills.forEach(bill => {
        const amount = employeesMap.get(bill.employeeId) || 0;
        employeesMap.set(bill.employeeId, amount + bill.totalPrice);
      });
      const sortedEmployees = Array.from(employeesMap.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([id, total]) => {
          const employee = employeesData.find(emp => emp.id === id);
          return {
            id,
            name: employee ? `${employee.name} ${employee.surname}` : 'Bilinmiyor',
            total
          };
        });
      setTopEmployees(sortedEmployees);

      // Process top customers
      const customersMap = new Map();
      bills.forEach(bill => {
        const amount = customersMap.get(bill.customerId) || 0;
        customersMap.set(bill.customerId, amount + bill.totalPrice);
      });
      const sortedCustomers = Array.from(customersMap.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([id, total]) => {
          const customer = customersData.find(cust => cust.id === id);
          return {
            id,
            name: customer ? `${customer.name} ${customer.surname}` : 'Bilinmiyor',
            total
          };
        });
      setTopCustomers(sortedCustomers);
    } catch (error) {
      console.error("Error fetching bills:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      const userData = await fetchUsers();
      if (userData) {
        await fetchData(userData.customersData, userData.employeesData);
      }
    };
    initialize();
  }, []);

  const handleFilter = async () => {
    setIsLoading(true);
    await fetchData(customers, employees);
  };

  const handleClearFilter = async () => {
    setIsLoading(true);
    const defaultDates = getDefaultDateRange();
    setStartDate(defaultDates.start);
    setEndDate(defaultDates.end);
    await fetchData(customers, employees);
  };

  return (
    <div className="p-6">
      {/* Header with New Badge */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <h1 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <TrendingUp className="h-6 w-6 text-blue-500" />
            İstatistikler
          </h1>
        </div>
      </div>

      {/* Filters Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center gap-2">
          <Filter className="h-5 w-5 text-blue-500" />
          Filtreler
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Date Filters */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Başlangıç Tarihi
            </label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              <input
                type="date"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Bitiş Tarihi
            </label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              <input
                type="date"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-end gap-4">
            <button
              onClick={handleFilter}
              className="flex items-center justify-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all"
            >
              <Filter className="h-4 w-4" />
              Filtrele
            </button>
            <button
              onClick={handleClearFilter}
              className="flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-all"
            >
              Filtreleri Sıfırla
            </button>
          </div>
        </div>
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {/* Top Service Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-purple-100 rounded-full">
              <Award className="h-6 w-6 text-purple-600" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">En Çok Yapılan Hizmet</h3>
              <p className="text-2xl font-semibold text-gray-900">
                {topServices[0]?.name || '-'}
              </p>
              <p className="text-sm text-gray-500">
                {topServices[0]?.count || 0} kez
              </p>
            </div>
          </div>
        </div>

        {/* Top Employee Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-blue-100 rounded-full">
              <UserCircle className="h-6 w-6 text-blue-600" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">En Yüksek Cirolu Çalışan</h3>
              <p className="text-2xl font-semibold text-gray-900">
                {topEmployees[0]?.name || '-'}
              </p>
              <p className="text-sm text-gray-500">
                {topEmployees[0]?.total?.toLocaleString('tr-TR') || 0} TL
              </p>
            </div>
          </div>
        </div>

        {/* Top Customer Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-100 rounded-full">
              <Users className="h-6 w-6 text-green-600" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">En Yüksek İşlemli Müşteri</h3>
              <p className="text-2xl font-semibold text-gray-900">
                {topCustomers[0]?.name || '-'}
              </p>
              <p className="text-sm text-gray-500">
                {topCustomers[0]?.total?.toLocaleString('tr-TR') || 0} TL
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tables Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Top Services Table */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-800 flex items-center gap-2">
              <Award className="h-5 w-5 text-purple-500" />
              En Çok Yapılan Hizmetler
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Hizmet Adı
                  </th>
                  <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    İşlem Sayısı
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {topServices.map((service, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors">
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {service.name}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right">
                      {service.count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Top Employees Table */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-800 flex items-center gap-2">
              <UserCircle className="h-5 w-5 text-blue-500" />
              En Yüksek Cirolu Çalışanlar
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Çalışan Adı
                  </th>
                  <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Toplam Ciro
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {topEmployees.map((employee, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors">
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {employee.name}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right">
                      {employee.total.toLocaleString('tr-TR')} TL
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Top Customers Table */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-800 flex items-center gap-2">
              <Users className="h-5 w-5 text-green-500" />
              En Yüksek İşlemli Müşteriler
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Müşteri Adı
                  </th>
                  <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Toplam Harcama
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {topCustomers.map((customer, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors">
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {customer.name}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right">
                      {customer.total.toLocaleString('tr-TR')} TL
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add loading state to the content */}
      <div className={`${isLoading ? 'opacity-50 pointer-events-none' : ''}`}>
        {/* Rest of your JSX remains the same... */}
      </div>
      
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bests; 