import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { Settings, PlusCircle, Edit, Trash2, X, Check, ChevronDown, ChevronUp, Plus, AlertCircle } from 'lucide-react';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

const Customization = () => {
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Form states
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [newSubCategory, setNewSubCategory] = useState('');
  const [isAddingSubCategory, setIsAddingSubCategory] = useState(false);

  // Form inputs
  const [categoryName, setCategoryName] = useState('');
  const [categoryStatus, setCategoryStatus] = useState('active');
  const [subCategories, setSubCategories] = useState([]);

  // Modal states
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // New state for showing deleted items
  const [showDeleted, setShowDeleted] = useState(false);

  // Fetch categories on component mount
  useEffect(() => {
      fetchCategories();
  }, [user, showDeleted]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch all categories from Firestore
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const categoriesCollection = collection(db, 'cost_categories');
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesList = categoriesSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => {
          // Sort by status (active first, then inactive, then deleted)
          const statusOrder = { active: 0, inactive: 1, deleted: 2 };
          const statusCompare = statusOrder[a.status || 'active'] - statusOrder[b.status || 'active'];
          
          // If status is the same, sort by name
          if (statusCompare === 0) {
            return (a.name || '').localeCompare(b.name || '');
          }
          return statusCompare;
        });
        
      // If showDeleted is false, filter out deleted categories
      const filteredCategories = showDeleted
        ? categoriesList
        : categoriesList.filter(cat => cat.status !== 'deleted');
        
      setCategories(filteredCategories);
      setError(null);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Kategorileri yüklerken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  // Add a new category
  const handleAddCategory = async (e) => {
    e.preventDefault();
    
    if (!categoryName.trim()) {
      setError('Kategori adı boş olamaz');
      return;
    }

    try {
      const newCategory = {
        name: categoryName.trim(),
        status: categoryStatus,
        subCategories: subCategories,
        createdAt: serverTimestamp()
      };

      await addDoc(collection(db, 'cost_categories'), newCategory);
      resetForm();
      fetchCategories();
      setError(null);
    } catch (error) {
      console.error('Error adding category:', error);
      setError('Kategori eklenirken bir hata oluştu.');
    }
  };

  // Update an existing category
  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    
    if (!categoryName.trim()) {
      setError('Kategori adı boş olamaz');
      return;
    }

    try {
      const categoryRef = doc(db, 'cost_categories', currentCategory.id);
      await updateDoc(categoryRef, {
        name: categoryName.trim(),
        status: categoryStatus,
        subCategories: subCategories,
        updatedAt: serverTimestamp()
      });
      
      resetForm();
      fetchCategories();
      setError(null);
    } catch (error) {
      console.error('Error updating category:', error);
      setError('Kategori güncellenirken bir hata oluştu.');
    }
  };

  // Delete a category
  const handleDeleteCategory = async () => {
    if (!categoryToDelete) return;
    
    try {
      // Instead of deleting, update the status to "deleted"
      const categoryRef = doc(db, 'cost_categories', categoryToDelete.id);
      await updateDoc(categoryRef, {
        status: 'deleted',
        updatedAt: serverTimestamp()
      });
      
      setShowDeleteModal(false);
      setCategoryToDelete(null);
      fetchCategories();
      setError(null);
    } catch (error) {
      console.error('Error updating category status:', error);
      setError('Kategori durumu güncellenirken bir hata oluştu.');
      setShowDeleteModal(false);
    }
  };

  // Edit a category
  const handleEditClick = (category) => {
    setCurrentCategory(category);
    setCategoryName(category.name);
    setCategoryStatus(category.status || 'active');
    setSubCategories(category.subCategories || []);
    setIsEditingCategory(true);
    setIsAddingCategory(false);
  };

  // Add a subcategory to the list
  const handleAddSubCategory = () => {
    if (!newSubCategory.trim()) return;
    
    const updatedSubCategories = [...subCategories, newSubCategory.trim()];
    setSubCategories(updatedSubCategories);
    setNewSubCategory('');
    setIsAddingSubCategory(false);
  };

  // Remove a subcategory from the list
  const handleRemoveSubCategory = (index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories.splice(index, 1);
    setSubCategories(updatedSubCategories);
  };

  // Reset form
  const resetForm = () => {
    setCategoryName('');
    setCategoryStatus('active');
    setSubCategories([]);
    setCurrentCategory(null);
    setIsAddingCategory(false);
    setIsEditingCategory(false);
    setExpandedCategory(null);
    setIsAddingSubCategory(false);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      {/* Header with New Badge */}
      <div className="flex flex-col mb-6">
        <div className="flex items-center gap-3">
          <h1 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <Settings className="h-6 w-6 text-blue-500" />
            Özelleştirme
          </h1>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 animate-pulse relative">
            Yeni
            <span className="absolute inset-0 rounded-full bg-blue-200 animate-ping opacity-75"></span>
          </span>
        </div>
      </div>

      {/* Error message */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700 flex items-start">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <span>{error}</span>
          <button 
            onClick={() => setError(null)} 
            className="ml-auto text-red-700 hover:text-red-900"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      )}

      {/* Content Tabs */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700 mb-4">Maliyet Kategorileri</h2>
        
        {/* Add Category Button */}
        <div className="flex justify-between items-center mb-4">
          {!isAddingCategory && !isEditingCategory && (
            <button
              onClick={() => {
                setIsAddingCategory(true);
                setIsEditingCategory(false);
                setCategoryName('');
                setCategoryStatus('active');
                setSubCategories([]);
              }}
              className="flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors"
            >
              <PlusCircle className="h-4 w-4 mr-2" />
              Yeni Kategori Ekle
            </button>
          )}
          
          <div className="flex items-center">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={showDeleted}
                onChange={() => setShowDeleted(!showDeleted)}
                className="form-checkbox h-4 w-4 text-blue-600 rounded focus:ring-2 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">Silinmiş kategorileri göster</span>
            </label>
          </div>
        </div>

        {/* Add/Edit Category Form */}
        {(isAddingCategory || isEditingCategory) && (
          <div className="bg-gray-50 p-4 rounded-md mb-6 border border-gray-200">
            <h3 className="text-md font-medium text-gray-700 mb-4">
              {isAddingCategory ? 'Yeni Kategori Ekle' : 'Kategori Düzenle'}
            </h3>
            
            <form onSubmit={isAddingCategory ? handleAddCategory : handleUpdateCategory}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Kategori Adı
                  </label>
                  <input
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Kategori adını girin"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Durum
                  </label>
                  <select
                    value={categoryStatus}
                    onChange={(e) => setCategoryStatus(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="active">Aktif</option>
                    <option value="inactive">Pasif</option>
                    <option value="deleted">Silinmiş</option>
                  </select>
                </div>
              </div>

              {/* Sub-categories */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Alt Kategoriler
                </label>
                
                {subCategories.length > 0 ? (
                  <ul className="mb-3 bg-white border border-gray-200 rounded-md divide-y">
                    {subCategories.map((subCat, index) => (
                      <li key={index} className="flex items-center justify-between px-3 py-2">
                        <span>{subCat}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveSubCategory(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm text-gray-500 italic mb-3">Henüz alt kategori eklenmedi.</p>
                )}

                {isAddingSubCategory ? (
                  <div className="flex items-center mb-2">
                    <input
                      type="text"
                      value={newSubCategory}
                      onChange={(e) => setNewSubCategory(e.target.value)}
                      className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Alt kategori adı"
                    />
                    <button
                      type="button"
                      onClick={handleAddSubCategory}
                      className="px-3 py-2 bg-green-500 text-white rounded-r-md hover:bg-green-600"
                    >
                      <Check className="h-4 w-4" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsAddingSubCategory(false);
                        setNewSubCategory('');
                      }}
                      className="px-3 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 ml-1"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() => setIsAddingSubCategory(true)}
                    className="flex items-center text-sm text-blue-500 hover:text-blue-700"
                  >
                    <Plus className="h-4 w-4 mr-1" />
                    Alt Kategori Ekle
                  </button>
                )}
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={resetForm}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  {isAddingCategory ? 'Ekle' : 'Güncelle'}
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Categories List */}
        <div className="bg-white rounded-md border border-gray-200 overflow-hidden">
          {loading ? (
            <div className="p-6 text-center text-gray-500">
              Yükleniyor...
            </div>
          ) : categories.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              Henüz kategori bulunmuyor.
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {categories.map((category) => (
                <li key={category.id} className={`border-b border-gray-200 last:border-b-0 ${category.status === 'deleted' ? 'bg-gray-50' : ''}`}>
                  <div className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <span 
                          className={`w-2 h-2 rounded-full mr-2 ${
                            category.status === 'active' 
                              ? 'bg-green-500' 
                              : category.status === 'deleted'
                                ? 'bg-gray-500'
                                : 'bg-red-500'
                          }`}
                        />
                        <h3 className={`text-md font-medium ${category.status === 'deleted' ? 'text-gray-500 line-through' : 'text-gray-800'}`}>
                          {category.name}
                        </h3>
                        <span 
                          className={`ml-2 px-2 py-0.5 text-xs rounded-full ${
                            category.status === 'active' 
                              ? 'bg-green-100 text-green-800' 
                              : category.status === 'deleted'
                                ? 'bg-gray-100 text-gray-800'
                                : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {category.status === 'active' 
                            ? 'Aktif' 
                            : category.status === 'deleted' 
                              ? 'Silinmiş' 
                              : 'Pasif'}
                        </span>
                      </div>
                      
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => setExpandedCategory(expandedCategory === category.id ? null : category.id)}
                          className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                        >
                          {expandedCategory === category.id ? (
                            <ChevronUp className="h-5 w-5" />
                          ) : (
                            <ChevronDown className="h-5 w-5" />
                          )}
                        </button>
                        
                        <button
                          onClick={() => handleEditClick(category)}
                          className="p-1 text-blue-500 hover:text-blue-700 rounded-full hover:bg-blue-50"
                          disabled={category.status === 'deleted'}
                        >
                          <Edit className={`h-5 w-5 ${category.status === 'deleted' ? 'opacity-50' : ''}`} />
                        </button>
                        
                        <button
                          onClick={() => {
                            setCategoryToDelete(category);
                            setShowDeleteModal(true);
                          }}
                          className={`p-1 rounded-full ${
                            category.status === 'deleted' 
                              ? 'text-gray-400 cursor-not-allowed' 
                              : 'text-red-500 hover:text-red-700 hover:bg-red-50'
                          }`}
                          disabled={category.status === 'deleted'}
                        >
                          <Trash2 className={`h-5 w-5 ${category.status === 'deleted' ? 'opacity-50' : ''}`} />
                        </button>
                      </div>
                    </div>
                    
                    {/* Subcategories */}
                    {expandedCategory === category.id && (
                      <div className="mt-3 ml-4 pl-2 border-l-2 border-gray-200">
                        <p className="text-sm font-medium text-gray-500 mb-1">Alt Kategoriler:</p>
                        {category.subCategories && category.subCategories.length > 0 ? (
                          <ul className="space-y-1">
                            {category.subCategories.map((subCat, index) => (
                              <li key={index} className="text-sm text-gray-600 flex items-center">
                                <span className="w-1.5 h-1.5 rounded-full bg-gray-400 mr-2"></span>
                                {subCat}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-sm text-gray-500 italic">Alt kategori bulunmuyor.</p>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Kategori Devre Dışı Bırakma</h3>
            <p className="text-gray-700 mb-4">
              <span className="font-medium">{categoryToDelete?.name}</span> kategorisini devre dışı bırakmak istediğinize emin misiniz? Bu işlem daha sonra geri alınabilir.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                İptal
              </button>
              <button
                onClick={handleDeleteCategory}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Devre Dışı Bırak
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Customization; 