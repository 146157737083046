import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Calendar, Clock, User, Briefcase, FileText, X, AlertCircle, Info } from 'lucide-react';
import SearchableDropdown from './SearchableDropdown';
import MultiServiceSelector from './MultiServiceSelector';

const AppointmentModal = ({
  isOpen,
  onClose,
  onSave,
  isEditing,
  appointmentForm,
  setAppointmentForm,
  customers,
  employees,
  services,
  selectedServices,
  setSelectedServices
}) => {
  const [timeError, setTimeError] = useState('');
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  // Handle time validation
  const validateTimeSlot = (time) => {
    // Reset error
    setTimeError('');
    
    // Simple validation example - can be expanded based on your business rules
    if (!time) {
      setTimeError('Lütfen bir saat seçin');
      return false;
    }
    
    return true;
  };

  const handleTimeChange = (time) => {
    const isValid = validateTimeSlot(time);
    if (isValid) {
      setAppointmentForm(prev => ({
        ...prev,
        time
      }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        },
        content: {
          position: 'relative',
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: 'auto',
          maxWidth: '600px',
          width: '90%',
          maxHeight: '90vh',
          margin: '0 auto',
          padding: 0,
          border: 'none',
          background: 'transparent',
          overflow: 'visible'
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-h-[90vh] overflow-y-auto">
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-xl font-semibold text-white">
            {isEditing ? 'Randevuyu Düzenle' : 'Yeni Randevu'}
          </h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-6 space-y-6">

          {/* Tarih Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <Calendar className="h-4 w-4 text-blue-500" />
              Tarih
            </label>
            <input
              type="date"
              value={appointmentForm.date}
              onChange={(e) => setAppointmentForm(prev => ({ ...prev, date: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
            />
          </div>

          {/* Saat Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <Clock className="h-4 w-4 text-blue-500" />
              Saat
            </label>
            <input
              type="time"
              value={appointmentForm.time}
              onChange={(e) => handleTimeChange(e.target.value)}
              className={`w-full px-3 py-2 border ${timeError ? 'border-red-300' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all`}
            />
            {timeError && (
              <div className="text-red-500 text-xs flex items-center mt-1">
                <AlertCircle className="h-3 w-3 mr-1" />
                {timeError}
              </div>
            )}
          </div>

          {/* Müşteri Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <User className="h-4 w-4 text-blue-500" />
              Müşteri
            </label>
            <SearchableDropdown
              items={customers}
              onSelect={(customer) =>
                setAppointmentForm((prev) => ({
                  ...prev,
                  customerId: customer.id,
                  selectedCustomer: customer
                }))
              }
              placeholder="Müşteri Ara..."
              value={appointmentForm.selectedCustomer}
              icon={User}
            />
          </div>

          {/* Çalışan Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <Briefcase className="h-4 w-4 text-blue-500" />
              Çalışan
            </label>
            <SearchableDropdown
              items={employees}
              onSelect={(employee) =>
                setAppointmentForm((prev) => ({
                  ...prev,
                  employeeId: employee.id,
                  selectedEmployee: employee
                }))
              }
              placeholder="Çalışan Ara..."
              value={appointmentForm.selectedEmployee}
              icon={Briefcase}
            />
          </div>

          {/* Hizmet Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <FileText className="h-4 w-4 text-blue-500" />
              Hizmetler
            </label>
            <MultiServiceSelector
              services={services}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              resetAfterSelect={true}
              maxServices={10}
            />
          </div>

          {/* Durum Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <AlertCircle className="h-4 w-4 text-blue-500" />
              Durum
            </label>
            <select
              value={appointmentForm.status}
              onChange={(e) => setAppointmentForm(prev => ({ ...prev, status: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
            >
              <option value="approved">Onaylandı</option>
              <option value="pending">Beklemede</option>
              <option value="completed">Tamamlandı</option>
              <option value="canceled">İptal</option>
            </select>
          </div>

          {/* Ödeme Yöntemi Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <FileText className="h-4 w-4 text-blue-500" />
              Ödeme Yöntemi
            </label>
            <select
              value={appointmentForm.paymentMethod || 'Nakit'}
              onChange={(e) => setAppointmentForm(prev => ({ ...prev, paymentMethod: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
            >
              <option value="Nakit">Nakit</option>
              <option value="Kredi Kartı">Kredi Kartı</option>
              <option value="Banka Havalesi">Banka Havalesi</option>
              <option value="Diğer">Diğer</option>
            </select>
          </div>

          {/* Notlar */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <FileText className="h-4 w-4 text-blue-500" />
              Notlar
            </label>
            <textarea
              value={appointmentForm.notes || ''}
              onChange={(e) => setAppointmentForm(prev => ({ ...prev, notes: e.target.value }))}
              placeholder="Randevu hakkında notlar..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
              rows={3}
            />
          </div>

          {/* Randevu Özeti */}
          {selectedServices.length > 0 && (
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="text-lg font-medium text-gray-900 mb-4 flex items-center justify-between">
                <span>Hizmet Tutarı:</span>
                <span>{selectedServices.reduce((sum, service) => sum + Number(service.price || 0), 0)} TL</span>
              </div>
            </div>
          )}
        </div>

        {/* Modal Footer */}
        <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
          >
            İptal
          </button>
          <button
            onClick={onSave}
            className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
            disabled={!appointmentForm.date || !appointmentForm.time || !appointmentForm.customerId || !appointmentForm.employeeId || selectedServices.length === 0}
          >
            {isEditing ? 'Güncelle' : 'Kaydet'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AppointmentModal; 