import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, where, orderBy, getDoc, doc, Timestamp, onSnapshot, addDoc, updateDoc, documentId } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { Calendar, Plus, Edit2, Trash2, Clock, User, Scissors, AlertCircle, CheckCircle, XCircle, RefreshCw } from 'lucide-react';
import AppointmentCalendar from '../components/AppointmentCalendar';
import AppointmentModal from '../components/AppointmentModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const Appointments = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [enrichedAppointments, setEnrichedAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [fetchErrors, setFetchErrors] = useState([]);
  
  // Added state for selected services
  const [selectedServices, setSelectedServices] = useState([]);
  
  // Appointment modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentForm, setAppointmentForm] = useState({
    date: formatISO(new Date(), { representation: 'date' }),
    time: '09:00',
    customerId: '',
    employeeId: '',
    status: 'approved',
    notes: '',
    paymentMethod: 'Nakit',
    selectedCustomer: null,
    selectedEmployee: null
  });
  
  // State to track if we're editing an appointment
  const [isEditing, setIsEditing] = useState(false);
  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  
  // State for delete confirmation dialog
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  
  // Add state for bill creation confirmation dialog
  const [billConfirmOpen, setBillConfirmOpen] = useState(false);
  const [appointmentToBill, setAppointmentToBill] = useState(null);
  
  // Use a ref to store the unsubscribe function
  const unsubscribeRef = useRef(null);

  // Status config with color mapping
  const statusConfig = {
    pending: { 
      label: 'Beklemede', 
      bgColor: 'bg-yellow-100', 
      textColor: 'text-yellow-800',
      icon: AlertCircle 
    },
    approved: { 
      label: 'Onaylandı', 
      bgColor: 'bg-blue-100', 
      textColor: 'text-blue-800',
      icon: CheckCircle 
    },
    canceled: { 
      label: 'İptal Edildi', 
      bgColor: 'bg-red-100', 
      textColor: 'text-red-800',
      icon: XCircle 
    },
    completed: { 
      label: 'Tamamlandı', 
      bgColor: 'bg-green-100', 
      textColor: 'text-green-800',
      icon: CheckCircle 
    },
    deleted: { 
      label: 'Silindi', 
      bgColor: 'bg-gray-100', 
      textColor: 'text-gray-800',
      icon: XCircle 
    }
  };

  // Helper function to parse Firebase date format to YYYY-MM-DD
  const parseFirebaseDate = (dateStr) => {
    if (!dateStr) return null;
    
    try {
      // Handle both timestamp and string formats
      if (typeof dateStr === 'string') {
        // Extract the date part from "February 11, 2025 at 1:00:00 PM UTC+3"
        const parts = dateStr.split(' at ');
        const datePart = parts[0]; // e.g., "February 11, 2025"
        
        const date = new Date(datePart);
        if (isNaN(date.getTime())) {
          console.error("Invalid date string:", dateStr);
          return null;
        }
        return formatDate(date);
      } else if (dateStr instanceof Timestamp) {
        // Handle Firestore timestamp
        return formatDate(dateStr.toDate());
      } else if (dateStr.toDate && typeof dateStr.toDate === 'function') {
        // Handle Firestore timestamp
        try {
          return formatDate(dateStr.toDate());
        } catch (e) {
          console.error("Error converting timestamp to date:", e);
          return null;
        }
      }
      
      return null;
    } catch (error) {
      console.error("Date parsing error:", error, "for date:", dateStr);
      return null;
    }
  };

  // Helper function to parse Firebase time format
  const parseFirebaseTime = (dateStr) => {
    if (!dateStr) return null;
    
    try {
      if (typeof dateStr === 'string') {
        // Extract the time part from "February 11, 2025 at 1:00:00 PM UTC+3"
        const parts = dateStr.split(' at ');
        if (parts.length < 2) return null;
        
        const timePart = parts[1].split(' ')[0]; // e.g., "1:00:00"
        return timePart.substring(0, 5); // Take only HH:MM
      } else if (dateStr instanceof Timestamp) {
        // Handle Firestore timestamp
        const date = dateStr.toDate();
        return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
      } else if (dateStr.toDate && typeof dateStr.toDate === 'function') {
        // Handle Firestore timestamp
        try {
          const date = dateStr.toDate();
          return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
        } catch (e) {
          console.error("Error converting timestamp to time:", e);
          return null;
        }
      }
      
      return null;
    } catch (error) {
      console.error("Time parsing error:", error, "for date:", dateStr);
      return null;
    }
  };

  // Helper function to get today's date in YYYY-MM-DD format
  function formatDateToday() {
    const today = new Date();
    return formatDate(today);
  }

  // Helper function to get tomorrow's date in YYYY-MM-DD format
  function formatDateTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return formatDate(tomorrow);
  }

  // Helper function to format date to YYYY-MM-DD
  function formatDate(date) {
    if (!date) return null;
    if (isNaN(date.getTime())) {
      console.error("Invalid date object:", date);
      return null;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Fetch appointments from Firestore - this is no longer async
  const fetchAppointments = () => {
    setLoading(true);
    setError(null);
    setFetchErrors([]);
    
    // Clear any existing subscription
    if (unsubscribeRef.current && typeof unsubscribeRef.current === 'function') {
      try {
        unsubscribeRef.current();
      } catch (error) {
        console.error("Error unsubscribing:", error);
      }
      unsubscribeRef.current = null;
    }
    
    try {
      // Default query for appointments
      const appointmentsRef = collection(db, "appointments");
      
      // Set up the onSnapshot listener
      const unsubscribe = onSnapshot(
        appointmentsRef,
        (querySnapshot) => {
          
          const appointmentData = [];
          
          // Process each appointment
          querySnapshot.docs.forEach(docSnapshot => {
            try {
              const data = docSnapshot.data();
              
              const appointment = {
                id: docSnapshot.id,
                ...data,
                date: parseFirebaseDate(data.date),
                time: parseFirebaseTime(data.date),
                status: data.status || 'pending',
                // Store original values in case we can't fetch related entities
                originalCustomerId: data.customerId,
                originalEmployeeId: data.employeeId,
                originalServices: data.services || [], // Handle new services array
                originalPaymentMethod: data.paymentMethod
              };
              
              appointmentData.push(appointment);
            } catch (docError) {
              console.error("Error processing appointment doc:", docError, docSnapshot.id);
            }
          });
          
          setAppointments(appointmentData);
          
          // Fetch related data (customers, services, employees)
          fetchRelatedData(appointmentData);
          
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching appointments:", error);
          setError(`Randevular yüklenirken hata oluştu: ${error.message}`);
          setLoading(false);
        }
      );
      
      // Store the unsubscribe function in the ref
      unsubscribeRef.current = unsubscribe;
      
      // We don't return anything here
    } catch (error) {
      console.error("Error setting up appointments listener:", error);
      setError(`Randevular yüklenirken hata oluştu: ${error.message}`);
      setLoading(false);
    }
  };

  // Fetch related data for appointments (customer names, service names, employee names)
  const fetchRelatedData = async (appointmentData) => {
    try {
      const newErrors = [];
      const customerIds = [...new Set(appointmentData.map(a => a.customerId).filter(Boolean))];
      const employeeIds = [...new Set(appointmentData.map(a => a.employeeId).filter(Boolean))];
      
      // Get all service IDs from both serviceId field and services array
      const serviceIds = [...new Set(appointmentData.reduce((acc, appointment) => {
        // Add the legacy serviceId if it exists
        if (appointment.serviceId) {
          acc.push(appointment.serviceId);
        }
        
        // Add all service IDs from the services array if it exists
        if (appointment.services && Array.isArray(appointment.services)) {
          appointment.services.forEach(service => {
            if (service.id) acc.push(service.id);
          });
        }
        
        return acc;
      }, []).filter(Boolean))];
      
      // Function to split an array into chunks of specified size
      const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };
      
      // Process IDs in batches of 30 (Firestore 'in' limit)
      const BATCH_SIZE = 30;
      
      // Batch fetch customer data using 'in' query with chunking
      let customerArray = [];
      if (customerIds.length > 0) {
        try {
          // Split customerIds into chunks of 30
          const customerIdChunks = chunkArray(customerIds, BATCH_SIZE);
          
          // Process each chunk separately
          for (const chunk of customerIdChunks) {
            const customersQuery = query(
              collection(db, "users"),
              where(documentId(), "in", chunk)
            );
            const customerSnapshot = await getDocs(customersQuery);
            
            // Add these customers to our array
            const chunkCustomers = customerSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            
            customerArray = [...customerArray, ...chunkCustomers];
          }
          
          // Check if any customers were not found
          const foundCustomerIds = customerArray.map(c => c.id);
          const missingCustomerIds = customerIds.filter(id => !foundCustomerIds.includes(id));
          
          if (missingCustomerIds.length > 0) {
            missingCustomerIds.forEach(id => {
              newErrors.push(`Müşteri bulunamadı: ${id}`);
            });
          }
        } catch (e) {
          console.error(`Error batch fetching customers:`, e);
          newErrors.push(`Müşteri verileri toplu alınamadı: ${e.message}`);
        }
      }
      
      // Batch fetch service data using 'in' query with chunking
      let serviceArray = [];
      if (serviceIds.length > 0) {
        try {
          // Split serviceIds into chunks of 30
          const serviceIdChunks = chunkArray(serviceIds, BATCH_SIZE);
          
          // Process each chunk separately
          for (const chunk of serviceIdChunks) {
            const servicesQuery = query(
              collection(db, "services"),
              where(documentId(), "in", chunk)
            );
            const serviceSnapshot = await getDocs(servicesQuery);
            
            // Add these services to our array
            const chunkServices = serviceSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            
            serviceArray = [...serviceArray, ...chunkServices];
          }
          
          // Check if any services were not found
          const foundServiceIds = serviceArray.map(s => s.id);
          const missingServiceIds = serviceIds.filter(id => !foundServiceIds.includes(id));
          
          if (missingServiceIds.length > 0) {
            missingServiceIds.forEach(id => {
              newErrors.push(`Hizmet bulunamadı: ${id}`);
            });
          }
        } catch (e) {
          console.error(`Error batch fetching services:`, e);
          newErrors.push(`Hizmet verileri toplu alınamadı: ${e.message}`);
        }
      }
      
      // Batch fetch employee data using 'in' query with chunking
      let employeeArray = [];
      if (employeeIds.length > 0) {
        try {
          // Split employeeIds into chunks of 30
          const employeeIdChunks = chunkArray(employeeIds, BATCH_SIZE);
          
          // Process each chunk separately
          for (const chunk of employeeIdChunks) {
            const employeesQuery = query(
              collection(db, "users"),
              where(documentId(), "in", chunk)
            );
            const employeeSnapshot = await getDocs(employeesQuery);
            
            // Add these employees to our array
            const chunkEmployees = employeeSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            
            employeeArray = [...employeeArray, ...chunkEmployees];
          }
          
          // Check if any employees were not found
          const foundEmployeeIds = employeeArray.map(e => e.id);
          const missingEmployeeIds = employeeIds.filter(id => !foundEmployeeIds.includes(id));
          
          if (missingEmployeeIds.length > 0) {
            missingEmployeeIds.forEach(id => {
              newErrors.push(`Çalışan bulunamadı: ${id}`);
            });
          }
        } catch (e) {
          console.error(`Error batch fetching employees:`, e);
          newErrors.push(`Çalışan verileri toplu alınamadı: ${e.message}`);
        }
      }
      
      // Set all data as arrays
      setCustomers(customerArray);
      setServices(serviceArray);
      setEmployees(employeeArray);
      
      if (newErrors.length > 0) {
        setFetchErrors(newErrors);
      }
      
      // Now enrich appointments with the fetched data
      const enriched = enrichAppointmentsWithRelatedData(appointmentData, customerArray, serviceArray, employeeArray);
      setEnrichedAppointments(enriched);
      
    } catch (error) {
      console.error("Error fetching related data:", error);
      setFetchErrors([`İlişkili veriler alınırken hata oluştu: ${error.message}`]);
    }
  };

  // Enrich appointments with customer, service, and employee names
  const enrichAppointmentsWithRelatedData = (appointmentData, customerData, serviceData, employeeData) => {
    return appointmentData.map(appointment => {
      // Find related customer data
      const customer = customerData.find(c => c.id === appointment.customerId);
      
      // Find related employee data
      const employee = employeeData.find(e => e.id === appointment.employeeId);
      
      // Process services data
      let services = [];
      let totalPrice = 0;
      
      // Handle the new services array format if it exists
      if (appointment.services && Array.isArray(appointment.services)) {
        services = appointment.services.map(appointmentService => {
          const serviceDetails = serviceData.find(s => s.id === appointmentService.id);
          const serviceInfo = {
            id: appointmentService.id,
            name: serviceDetails ? serviceDetails.name : 'Bilinmeyen Hizmet',
            price: serviceDetails ? serviceDetails.price : 0
          };
          
          totalPrice += Number(serviceInfo.price || 0);
          return serviceInfo;
        });
      } 
      // For backward compatibility, handle the old serviceId field
      else if (appointment.serviceId) {
        const serviceDetails = serviceData.find(s => s.id === appointment.serviceId);
        if (serviceDetails) {
          services = [{
            id: appointment.serviceId,
            name: serviceDetails.name,
            price: serviceDetails.price
          }];
          totalPrice = Number(serviceDetails.price || 0);
        }
      }
      
      // Combine services names for display
      const serviceNames = services.map(s => s.name).join(', ');
      
      return {
        ...appointment,
        customerName: customer ? `${customer.name} ${customer.surname || ''}`.trim() : 'Bilinmeyen Müşteri',
        employeeName: employee ? `${employee.name} ${employee.surname || ''}`.trim() : 'Bilinmeyen Çalışan',
        services: services,
        serviceName: serviceNames || 'Bilinmeyen Hizmet',
        price: totalPrice,
      };
    });
  };

  useEffect(() => {
    // Trigger fetch whenever not in loading state
    if (!authLoading) {
      fetchAppointments();
    }
    
    // Clean up subscription on unmount
    return () => {
      if (unsubscribeRef.current && typeof unsubscribeRef.current === 'function') {
        try {
          unsubscribeRef.current();
        } catch (error) {
          console.error("Error during cleanup:", error);
        }
      }
    };
  }, [user, authLoading]);

  // Effect to update enriched appointments when related data changes
  useEffect(() => {
    if (appointments.length > 0) {
      enrichAppointmentsWithRelatedData(appointments, customers, services, employees);
    }
  }, [appointments, customers, services, employees]);

  // Get customer name from customerId
  const getCustomerName = (customerId) => {
    const customer = customers.find(c => c.id === customerId);
    return customer ? `${customer.name} ${customer.surname || ''}`.trim() : 'Bilinmeyen Müşteri';
  };

  // Get service name from serviceId
  const getServiceName = (serviceId) => {
    const service = services.find(s => s.id === serviceId);
    return service ? service.name : 'Bilinmeyen Hizmet';
  };

  // Get employee name from employeeId
  const getEmployeeName = (employeeId) => {
    const employee = employees.find(e => e.id === employeeId);
    return employee ? `${employee.name} ${employee.surname || ''}`.trim() : 'Bilinmeyen Çalışan';
  };

  // Handle day selection from calendar
  const handleDaySelect = (date, dateAppointments) => {
    setSelectedDate(date);
    
    // Filter appointments for the selected day (excluding deleted appointments)
    const appointmentsForDay = enrichedAppointments.filter(a => a.date === date && a.status !== 'deleted');
    setSelectedAppointments(appointmentsForDay);
  };

  // Count appointments by status
  const countAppointmentsByStatus = (status) => {
    // Don't count deleted appointments for any status other than specifically counting deleted ones
    if (status !== 'deleted') {
      return enrichedAppointments.filter(a => a.status === status && a.status !== 'deleted').length;
    } else {
      return enrichedAppointments.filter(a => a.status === 'deleted').length;
    }
  };

  // Count today's appointments
  const countTodaysAppointments = () => {
    const today = formatDateToday();
    return enrichedAppointments.filter(a => a.date === today && a.status !== "deleted").length;
  };

  // Modal handlers
  const handleOpenModal = () => {
    // Reset editing state
    setIsEditing(false);
    setCurrentAppointmentId(null);
    
    // Reset form to default values
    setAppointmentForm({
      date: formatISO(new Date(), { representation: 'date' }),
      time: '09:00',
      customerId: '',
      employeeId: '',
      status: 'approved',
      notes: '',
      paymentMethod: 'Nakit',
      selectedCustomer: null,
      selectedEmployee: null
    });
    
    // Reset selected services
    setSelectedServices([]);
    
    // Fetch all available customers, employees, and services for dropdown
    fetchAllDropdownData();
    
    setIsModalOpen(true);
  };

  // Fetch all customers, employees, and services for dropdowns
  const fetchAllDropdownData = async () => {
    try {
      // Use Promise.all to fetch all data in parallel
      const [customerSnapshot, employeeSnapshot, serviceSnapshot] = await Promise.all([
        // Fetch all customers
        getDocs(
          query(
            collection(db, "users"),
            where("role", "==", "customer"),
            where("status", "==", "active")
          )
        ),
        
        // Fetch all employees
        getDocs(
          query(
            collection(db, "users"),
            where("role", "==", "employee"),
            where("status", "==", "active")
          )
        ),
        
        // Fetch all services
        getDocs(
          query(
            collection(db, "services"),
            where("status", "==", "active")
          )
        )
      ]);
      
      // Process results
      const customerData = customerSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCustomers(customerData);
      
      const employeeData = employeeSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEmployees(employeeData);
      
      const serviceData = serviceSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setServices(serviceData);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      toast.error("Dropdown verileri yüklenirken hata oluştu");
    }
  };

  // Prepare formatted data for dropdowns
  const prepareCustomersForDropdown = () => {
    if (!customers || !Array.isArray(customers)) {
      console.warn('Invalid customers data:', customers);
      return [];
    }
    return customers; // Already in the correct format
  };

  const prepareEmployeesForDropdown = () => {
    if (!employees || !Array.isArray(employees)) {
      console.warn('Invalid employees data:', employees);
      return [];
    }
    return employees; // Already in the correct format
  };

  const prepareServicesForDropdown = () => {
    if (!services || !Array.isArray(services)) {
      console.warn('Invalid services data:', services);
      return [];
    }
    return services; // Already in the correct format
  };

  const handleCloseModal = () => {
    // Reset editing state
    setIsEditing(false);
    setCurrentAppointmentId(null);
    setIsModalOpen(false);
  };

  const handleSaveAppointment = async () => {
    try {
      // Validate form
      if (!appointmentForm.date || !appointmentForm.time || 
          !appointmentForm.customerId || !appointmentForm.employeeId || 
          selectedServices.length === 0) {
        toast.error('Lütfen tüm gerekli alanları doldurun', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      setLoading(true);

      // Create a Date object from the date and time inputs
      const dateTimeStr = `${appointmentForm.date}T${appointmentForm.time}:00`;
      const appointmentDateTime = new Date(dateTimeStr);
      
      // Group services by ID and count occurrences (sessions)
      const groupedServices = selectedServices.reduce((acc, service) => {
        const existingService = acc.find(s => s.id === service.id);
        if (existingService) {
          existingService.sessions++;
        } else {
          acc.push({
            id: service.id,
            name: service.name,
            price: service.price,
            sessions: 1
          });
        }
        return acc;
      }, []);
      
      // Prepare data for Firestore
      const appointmentData = {
        customerId: appointmentForm.customerId,
        employeeId: appointmentForm.employeeId,
        services: groupedServices, // Store the array of services
        date: Timestamp.fromDate(appointmentDateTime),
        status: appointmentForm.status || 'approved',
        notes: appointmentForm.notes || '',
        paymentMethod: appointmentForm.paymentMethod || 'Nakit', // Default to cash if not specified
        totalPrice: selectedServices.reduce((sum, service) => sum + Number(service.price || 0), 0)
      };

      if (isEditing && currentAppointmentId) {
        // Update existing appointment
        appointmentData.updatedAt = Timestamp.now();
        
        const appointmentRef = doc(db, 'appointments', currentAppointmentId);
        await updateDoc(appointmentRef, appointmentData);
        
        toast.success('Randevu başarıyla güncellendi', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        // Add new appointment
        appointmentData.createdAt = Timestamp.now();
        appointmentData.updatedAt = Timestamp.now();
        appointmentData.userId = user?.uid || ''; // Add the current user's ID
        
        await addDoc(collection(db, 'appointments'), appointmentData);
        
        toast.success('Randevu başarıyla oluşturuldu', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      
      // Reset form and close modal
      setAppointmentForm({
        date: formatISO(new Date(), { representation: 'date' }),
        time: '09:00',
        customerId: '',
        employeeId: '',
        status: 'approved',
        notes: '',
        paymentMethod: 'Nakit',
        selectedCustomer: null,
        selectedEmployee: null
      });
      
      // Reset selected services
      setSelectedServices([]);
      
      // Reset editing state
      setIsEditing(false);
      setCurrentAppointmentId(null);
      
      setIsModalOpen(false);
      
      // Refresh appointments
      fetchAppointments();
    } catch (error) {
      console.error('Error saving appointment:', error);
      toast.error(isEditing ? 'Randevu güncellenirken hata oluştu' : 'Randevu kaydedilirken hata oluştu', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditAppointment = async (appointment) => {
    try {
      setLoading(true);
      
      // Fetch all available customers, employees, and services for dropdown
      await fetchAllDropdownData();
      
      // Find the related customer, employee, and service objects
      const selectedCustomer = customers.find(c => c.id === appointment.customerId);
      const selectedEmployee = employees.find(e => e.id === appointment.employeeId);
      
      // Convert date string to proper format for the date input (YYYY-MM-DD)
      // The appointment.date is already formatted in parseFirebaseDate
      const dateForInput = appointment.date;
      
      setIsEditing(true);
      setCurrentAppointmentId(appointment.id);
      
      // Format the date and time properly
      setAppointmentForm({
        date: dateForInput,
        time: appointment.time || '09:00',
        customerId: appointment.customerId,
        employeeId: appointment.employeeId,
        status: appointment.status || 'pending',
        notes: appointment.notes || '',
        paymentMethod: appointment.paymentMethod || 'Nakit',
        selectedCustomer,
        selectedEmployee
      });
      
      // Set selected services
      if (appointment.services && Array.isArray(appointment.services)) {
        // Expand services to individual items based on sessions
        const expandedServices = appointment.services.reduce((acc, service) => {
          // Create an array with service repeated 'sessions' times
          const servicesArray = Array(service.sessions || 1).fill({
            id: service.id,
            name: service.name,
            price: service.price
          });
          return [...acc, ...servicesArray];
        }, []);
        
        setSelectedServices(expandedServices);
      } 
      // For backward compatibility, handle the old serviceId field
      else if (appointment.serviceId) {
        const serviceDetails = services.find(s => s.id === appointment.serviceId);
        if (serviceDetails) {
          setSelectedServices([{
            id: serviceDetails.id,
            name: serviceDetails.name,
            price: serviceDetails.price
          }]);
        } else {
          setSelectedServices([]);
        }
      } else {
        setSelectedServices([]);
      }
      
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error preparing appointment for edit:', error);
      toast.error('Randevu düzenleme hazırlanırken hata oluştu', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } finally {
      setLoading(false);
    }
  };

  // Show delete confirmation dialog
  const handleShowDeleteConfirm = (appointment) => {
    setAppointmentToDelete(appointment);
    setDeleteConfirmOpen(true);
  };

  // Cancel delete operation
  const handleCancelDelete = () => {
    setAppointmentToDelete(null);
    setDeleteConfirmOpen(false);
  };

  // Confirm delete operation (soft delete by updating status)
  const handleConfirmDelete = async () => {
    if (!appointmentToDelete) return;

    try {
      setLoading(true);
      
      // Get reference to the appointment document
      const appointmentRef = doc(db, 'appointments', appointmentToDelete.id);
      
      // Update the status to "deleted" (soft delete)
      await updateDoc(appointmentRef, { 
        status: 'deleted',
        updatedAt: Timestamp.now()
      });
      
      toast.success('Randevu başarıyla silindi', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      
      // Close the confirm dialog and reset state
      setDeleteConfirmOpen(false);
      setAppointmentToDelete(null);
      
      // Refresh appointments
      fetchAppointments();
    } catch (error) {
      console.error('Error deleting appointment:', error);
      toast.error('Randevu silinirken hata oluştu', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle showing bill confirmation dialog
  const handleShowBillConfirm = (appointment) => {
    setAppointmentToBill(appointment);
    setBillConfirmOpen(true);
  };

  // Cancel bill creation
  const handleCancelBill = () => {
    setAppointmentToBill(null);
    setBillConfirmOpen(false);
  };

  // Modified to show confirmation dialog instead of directly completing
  const handleCompleteAppointment = async (appointment) => {
    // Show confirmation dialog for bill creation
    handleShowBillConfirm(appointment);
  };

  // This function handles the appointment completion and/or bill creation
  const handleConfirmComplete = async (createBill) => {
    if (!appointmentToBill) return;

    try {
      setLoading(true);
      
      // Get reference to the appointment document
      const appointmentRef = doc(db, 'appointments', appointmentToBill.id);
      
      // Update the status to "completed"
      await updateDoc(appointmentRef, { 
        status: 'completed',
        updatedAt: Timestamp.now()
      });
      
      toast.success('Randevu başarıyla tamamlandı', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      
      // Navigate to home with appointment data if bill creation is requested
      if (createBill) {
        // Navigate to Home page with the appointment data for bill creation
        navigate('/home', { 
          state: { 
            createBillFromAppointment: true,
            appointmentData: {
              customerId: appointmentToBill.customerId,
              employeeId: appointmentToBill.employeeId,
              services: appointmentToBill.services || []
            }
          }
        });
      } else {
        // Just refresh appointments if no bill is needed
        fetchAppointments();
      }
    } catch (error) {
      console.error('Error completing appointment:', error);
      toast.error('Randevu tamamlanırken hata oluştu', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } finally {
      setLoading(false);
      setBillConfirmOpen(false);
      setAppointmentToBill(null);
    }
  };

  // Helper function to clear selected date
  const clearSelectedDate = () => {
    setSelectedDate(null);
    setSelectedAppointments([]);
  };

  // Format date for display (converts YYYY-MM-DD to more readable format)
  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('tr-TR', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      }).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateString;
    }
  };

  return (
    <div>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      <div className="max-w-7xl mx-auto px-4 py-6">
        {/* Header with New Badge */}
        <div className="flex flex-col mb-6">
            <div className="flex items-center gap-3">
                <h1 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
                    <Calendar className="h-6 w-6 text-blue-500" />
                    Randevular
                </h1>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 animate-pulse relative">
                    Yeni
                    <span className="absolute inset-0 rounded-full bg-blue-200 animate-ping opacity-75"></span>
                </span>
            </div>
            <p className="text-gray-600 mt-1">Randevu takip ve yönetim sistemi</p>
        </div>
        
        {fetchErrors.length > 0 && (
          <div className="mb-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-yellow-400" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">İlişkili verileri alırken bazı sorunlar oluştu</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>Aşağıdaki nedenlerden dolayı bazı müşteri, hizmet veya çalışan bilgileri gösterilemiyor olabilir:</p>
                  <ul className="list-disc pl-5 space-y-1 mt-2">
                    {fetchErrors.slice(0, 3).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                    {fetchErrors.length > 3 && <li>... ve {fetchErrors.length - 3} daha fazla hata</li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Calendar section */}
          <div className="lg:col-span-2">
            <AppointmentCalendar 
              appointments={enrichedAppointments} 
              onDaySelect={handleDaySelect} 
            />
          </div>
          
          {/* Appointment actions section */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold text-gray-800">Hızlı İşlemler</h2>
              <button 
                onClick={handleOpenModal}
                className="flex items-center bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-3 py-2 rounded-md text-sm hover:from-cyan-600 hover:to-blue-600 transition-colors"
              >
                <Plus className="h-4 w-4 mr-1" />
                Yeni Randevu
              </button>
            </div>
            
            <div className="p-4 bg-blue-50 border border-blue-100 rounded-md mb-4">
              <div className="flex items-start space-x-2">
                <Calendar className="h-5 w-5 text-blue-500 mt-0.5" />
                <div>
                  <p className="text-blue-800 font-medium">
                    Randevu İpuçları
                  </p>
                  <p className="text-blue-600 mt-1 text-sm">
                    Takvimden bir güne tıklayarak o günün randevularını görüntüleyebilirsiniz.
                  </p>
                  <p className="text-blue-600 mt-1 text-sm">
                    "Tamamla" butonuna basarak randevuya ait yeni bir adisyon oluşturabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="space-y-2">
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-md border border-gray-200">
                <div className="flex items-center">
                  <Clock className="h-4 w-4 text-gray-500 mr-2" />
                  <span>Bugünkü Randevular</span>
                </div>
                <span className="bg-blue-100 text-blue-800 text-xs font-semibold py-1 px-2 rounded-full">
                  {countTodaysAppointments()}
                </span>
              </div>
              
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-md border border-gray-200">
                <div className="flex items-center">
                  <AlertCircle className="h-4 w-4 text-yellow-500 mr-2" />
                  <span>Bekleyen Randevular</span>
                </div>
                <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold py-1 px-2 rounded-full">
                  {countAppointmentsByStatus('pending')}
                </span>
              </div>
              
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-md border border-gray-200">
                <div className="flex items-center">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  <span>Tamamlanan Randevular</span>
                </div>
                <span className="bg-green-100 text-green-800 text-xs font-semibold py-1 px-2 rounded-full">
                  {countAppointmentsByStatus('completed')}
                </span>
              </div>

              <button 
                onClick={() => {
                  setLoading(true);
                  setFetchErrors([]);
                  fetchAppointments();
                }}
                className="w-full mt-2 flex items-center justify-center py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-sm text-gray-700 transition-colors"
              >
                <RefreshCw className="h-4 w-4 mr-2" />
                Randevuları Yenile
              </button>
            </div>
          </div>
        </div>
        
        {/* Upcoming appointments section */}
        <div className="mt-6 bg-white shadow-md rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-800">
              {selectedDate 
                ? `${formatDateForDisplay(selectedDate)} Tarihli Randevular` 
                : "Yaklaşan Randevular"}
            </h2>
            
            {selectedDate && (
              <button 
                onClick={clearSelectedDate} 
                className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
              >
                <RefreshCw className="h-3 w-3 mr-1" />
                Tüm randevuları göster
              </button>
            )}
          </div>
          
          {loading ? (
            <div className="text-center py-8">
              <div className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm rounded-md text-blue-800 bg-blue-100">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Randevular yükleniyor...
              </div>
            </div>
          ) : error ? (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 my-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          ) : (selectedDate && selectedAppointments.length > 0) || (!selectedDate && enrichedAppointments.length > 0) ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tarih & Saat</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Müşteri</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hizmetler</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Durum</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Çalışan</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ödeme</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">İşlemler</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {(selectedDate ? selectedAppointments : enrichedAppointments
                    .filter(a => {
                      // Filter out deleted and canceled appointments
                      if (a.status === 'deleted' || a.status === 'canceled') return false;
                      
                      // When not in selected date mode, only show today and future appointments
                      if (!selectedDate) {
                        const today = formatDateToday();
                        // Keep only appointments that are today or in the future
                        return a.date >= today;
                      }
                      
                      return true;
                    })
                    .sort((a, b) => {
                      // First by date
                      const dateA = a.date ? new Date(a.date) : new Date(0);
                      const dateB = b.date ? new Date(b.date) : new Date(0);
                      
                      if (dateA.getTime() !== dateB.getTime()) {
                        return dateA - dateB;
                      }
                      
                      // Then by time if dates are equal
                      const timeA = a.time || '00:00';
                      const timeB = b.time || '00:00';
                      return timeA.localeCompare(timeB);
                    })
                    .slice(0, 10))
                    .sort((a, b) => {
                      // Sort by time only if we're viewing a specific date
                      if (selectedDate) {
                        const timeA = a.time || '00:00';
                        const timeB = b.time || '00:00';
                        return timeA.localeCompare(timeB);
                      }
                      return 0;
                    })
                    .map((appointment) => {
                      const StatusIcon = statusConfig[appointment.status]?.icon || AlertCircle;
                      
                      return (
                        <tr key={appointment.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">{appointment.date || 'Tarih Bilinmiyor'}</div>
                            <div className="text-sm text-gray-500">{appointment.time || 'Saat Bilinmiyor'}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{appointment.customerName}</div>
                            {appointment.originalCustomerId && appointment.customerName === 'Bilinmeyen Müşteri' && (
                              <div className="text-xs text-gray-500">{appointment.originalCustomerId.substring(0, 8)}...</div>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex flex-wrap gap-1">
                              {appointment.services && appointment.services.length > 0 ? (
                                appointment.services.map((service, index) => (
                                  <span key={`${service.id}-${index}`} className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                    {service.name} {service.sessions > 1 ? `(${service.sessions})` : ''}
                                  </span>
                                ))
                              ) : (
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                  {appointment.serviceName}
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full ${statusConfig[appointment.status]?.bgColor || 'bg-gray-100'} ${statusConfig[appointment.status]?.textColor || 'text-gray-800'}`}>
                              <StatusIcon className="h-3 w-3 mr-1" />
                              {statusConfig[appointment.status]?.label || appointment.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {appointment.employeeName}
                            {appointment.originalEmployeeId && appointment.employeeName === 'Bilinmeyen Çalışan' && (
                              <div className="text-xs text-gray-500">{appointment.originalEmployeeId.substring(0, 8)}...</div>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {appointment.paymentMethod || '-'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {appointment.status === 'approved' && (
                              <button 
                                className="text-green-600 hover:text-green-900 mr-3"
                                onClick={() => handleCompleteAppointment(appointment)}
                                title="Tamamla"
                              >
                                <CheckCircle className="h-4 w-4" />
                              </button>
                            )}
                            <button className="text-blue-600 hover:text-blue-900 mr-3" onClick={() => handleEditAppointment(appointment)}>
                              <Edit2 className="h-4 w-4" />
                            </button>
                            <button className="text-red-600 hover:text-red-900" onClick={() => handleShowDeleteConfirm(appointment)}>
                              <Trash2 className="h-4 w-4" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-500 text-center py-4">
              {selectedDate 
                ? `${formatDateForDisplay(selectedDate)} tarihinde randevu bulunmamaktadır.` 
                : "Yaklaşan randevu bulunmamaktadır."}
            </p>
          )}
        </div>
        
        {/* Appointment Modal */}
        <AppointmentModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveAppointment}
          isEditing={isEditing}
          appointmentForm={appointmentForm}
          setAppointmentForm={setAppointmentForm}
          customers={prepareCustomersForDropdown()}
          employees={prepareEmployeesForDropdown()}
          services={prepareServicesForDropdown()}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
        />
        
        {/* Delete Confirmation Dialog */}
        {deleteConfirmOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Randevu Silme Onayı</h3>
              <p className="text-gray-600 mb-6">
                Bu randevuyu silmek istediğinizden emin misiniz? 
                <span className="text-red-500 block mt-1 text-sm">
                  Bu işlem geri alınamaz.
                </span>
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={handleCancelDelete}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 border border-gray-300 rounded-md"
                >
                  İptal
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md"
                >
                  Sil
                </button>
              </div>
            </div>
          </div>
        )}
        
        {/* Bill Confirmation Dialog */}
        {billConfirmOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Randevu Tamamlama</h3>
              <p className="text-gray-600 mb-6">
                Bu randevu için adisyon oluşturmak istiyor musunuz?
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => handleConfirmComplete(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 border border-gray-300 rounded-md"
                >
                  Hayır, Sadece Tamamla
                </button>
                <button
                  onClick={() => handleConfirmComplete(true)}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                >
                  Evet, Adisyon Oluştur
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Appointments; 