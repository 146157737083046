import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  serverTimestamp
} from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { validateBill } from '../utils/validationUtils';
import { withRateLimit } from '../utils/firebaseMiddleware';
import { AuditLogger } from '../utils/auditLogger';
import SearchableDropdown from '../components/SearchableDropdown';
import BillCard from '../components/BillCard';
import BillModal from '../components/BillModal';
import PaymentSummary from '../components/PaymentSummary';
import CostCard from '../components/CostCard';
import CostModal from '../components/CostModal';
import { Search } from 'lucide-react';

function Home() {

const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
const [isServiceModalOpen, setServiceModalOpen] = useState(false);
const [customerSearch, setCustomerSearch] = useState(""); // Müşteri arama state'i
const [applyDiscount, setApplyDiscount] = useState(false); // İndirim kutucuğu durumu
const [discountAmount, setDiscountAmount] = useState(0); // İndirim tutarı

// getCurrentDate fonksiyonu
const getCurrentDate = () => {
  const now = new Date();
  // GMT+3 için offset hesaplama (3 saat)
  const offset = 3;
  const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
  const targetTime = new Date(utc + (3600000 * offset));
  
  // Tarihi YYYY-MM-DD formatında formatla
  const year = targetTime.getFullYear();
  const month = String(targetTime.getMonth() + 1).padStart(2, '0');
  const day = String(targetTime.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

// isToday fonksiyonu
const isToday = (dateStr) => {
  const today = getCurrentDate(); // Güncel tarihi al
  return dateStr === today; // Direkt string karşılaştırması yap
};

const [today, setToday] = useState(getCurrentDate());

  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [bills, setBills] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [services, setServices] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [billForm, setBillForm] = useState({
    date: new Date().toISOString().split('T')[0],
    customerId: "",
    employeeId: "",
    services: [],
    price: "",
    discount: "",
    paymentType: "Nakit",
    status: "active",
  });
  const [pendingAppointmentData, setPendingAppointmentData] = useState(null);

  useEffect(() => {
    setToday(getCurrentDate());

    // Store appointment data if present
    if (location.state?.createBillFromAppointment && location.state?.appointmentData) {
      setPendingAppointmentData(location.state.appointmentData);
      // Clear navigation state to prevent reopening on refresh
      window.history.replaceState({}, document.title);
    }

    const loadAllData = async () => {
      try {
        // Fetch customers, employees, and services with status check
        const [customerSnapshot, employeeSnapshot, serviceSnapshot] = await Promise.all([
          getDocs(query(
            collection(db, "users"), 
            where("role", "==", "customer"),
            where("status", "==", "active")
          )),
          getDocs(query(
            collection(db, "users"), 
            where("role", "==", "employee"),
            where("status", "==", "active")
          )),
          getDocs(query(
            collection(db, "services"),
            where("status", "==", "active")
          ))
        ]);

        const customersData = customerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const employeesData = employeeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const servicesData = serviceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        setCustomers(customersData);
        setEmployees(employeesData);
        setServices(servicesData);

        // Modified bills query
        const billsRef = collection(db, "bills");
        let billsQuery;

        if (user.role === 'admin') {
          const dateRange = getDateRange();
          billsQuery = query(
            billsRef,
            where("status", "==", "active"),
            where("date", "in", dateRange)
          );
        } else {
          // Cashier only sees today's bills
          const today = new Date().toISOString().split('T')[0];
          billsQuery = query(
            billsRef,
            where("status", "==", "active"),
            where("date", "==", today)
          );
        }

        const billsSnapshot = await getDocs(billsQuery);
        const billsData = billsSnapshot.docs.map((doc) => {
          const billData = doc.data();
          const customer = customersData.find((c) => c.id === billData.customerId);
          const employee = employeesData.find((e) => e.id === billData.employeeId);
          
          return {
            id: doc.id,
            ...billData,
            customerName: customer ? `${customer.name} ${customer.surname}` : "Bilinmiyor",
            employeeName: employee ? `${employee.name} ${employee.surname}` : "Bilinmiyor",
            serviceNames: billData.services?.map(s => s.name).join(", ") || "Bilinmiyor"
          };
        });

        setBills(billsData);
        await fetchCosts();
      } catch (error) {
        console.error("Veri yükleme hatası:", error);
      }
    };

    loadAllData();
  }, [user.role, location.state]);

  useEffect(() => {
    // Only proceed if we have pending appointment data AND customers/employees are loaded
    if (pendingAppointmentData && customers.length > 0 && employees.length > 0 && services.length > 0) {
      createBillFromAppointment(pendingAppointmentData);
      // Clear pending data to avoid reprocessing
      setPendingAppointmentData(null);
    }
  }, [pendingAppointmentData, customers.length, employees.length, services.length]);

  const getDateColor = (dateStr) => {
    return isToday(dateStr) ? "#4CAF50" : "#FF9800";
  };

  // Helper function to get date range
  const getDateRange = () => {
    const today = new Date();
    const dates = [];
    
    // Add today
    dates.push(today.toISOString().split('T')[0]);
    
    // Add last 3 days
    for (let i = 1; i <= 3; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split('T')[0]);
    }
    
    return dates;
  };

const fetchCustomers = async () => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"), 
        where("role", "==", "customer"),
        where("status", "==", "active") // status kontrolü eklendi
      )
    );
    setCustomers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  } catch (error) {
    console.error("Müşteri verisi çekme hatası:", error);
  }
};

const fetchEmployees = async () => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"), 
        where("role", "==", "employee"),
        where("status", "==", "active") // status kontrolü eklendi
      )
    );
    setEmployees(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  } catch (error) {
    console.error("Çalışan verisi çekme hatası:", error);
  }
};

const fetchServices = async () => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "services"),
        where("status", "==", "active") // status kontrolü eklendi
      )
    );
    setServices(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  } catch (error) {
    console.error("İşlem verisi çekme hatası:", error);
  }
};

const fetchBills = async () => {
  try {
    const billsRef = collection(db, "bills");
    let billsQuery;

    if (user.role === 'cashier') {
      // Bugünün tarihini YYYY-MM-DD formatında al
      const today = getCurrentDate();
      
      billsQuery = query(
        billsRef,
        where("status", "==", "active"),
        where("date", "==", today)  // Tam eşitlik kontrolü yap
      );
    } else {
      billsQuery = query(billsRef, where("status", "==", "active"));
    }

    const billsSnapshot = await getDocs(billsQuery);
    
    const billsData = billsSnapshot.docs.map((doc) => {
      const billData = doc.data();
      const customer = customers.find((c) => c.id === billData.customerId);
      const employee = employees.find((e) => e.id === billData.employeeId);
      
      return {
        id: doc.id,
        ...billData,
        customerName: customer ? `${customer.name} ${customer.surname}` : "Bilinmiyor",
        employeeName: employee ? `${employee.name} ${employee.surname}` : "Bilinmiyor",
        serviceNames: billData.services?.map(s => s.name).join(", ") || "Bilinmiyor"
      };
    });

    setBills(billsData);
  } catch (error) {
    console.error("Adisyonları getirirken hata oluştu:", error);
  }
};

const handleOpenModal = (bill = null) => {
  setModalIsOpen(true);
  if (bill) {
    setIsEditing(true);
    setSelectedBillId(bill.id);
    
    const selectedCustomer = customers.find(c => c.id === bill.customerId);
    const selectedEmployee = employees.find(e => e.id === bill.employeeId);
    
    // Servisleri seans sayısı kadar çoğalt
    const expandedServices = bill.services.reduce((acc, service) => {
      // Her bir servis için sessions sayısı kadar servis ekle
      const servicesArray = Array(service.sessions).fill({
        id: service.id,
        name: service.name,
        price: service.price
      });
      return [...acc, ...servicesArray];
    }, []);
    
    setBillForm({
      date: bill.date,
      customerId: bill.customerId,
      employeeId: bill.employeeId,
      price: bill.price,
      discount: bill.discount || 0,
      paymentType: bill.paymentType || "Nakit",
      status: bill.status,
      selectedCustomer: selectedCustomer,
      selectedEmployee: selectedEmployee,
    });

    setApplyDiscount(bill.discount > 0);
    setDiscountAmount(bill.discount || 0);
    setSelectedServices(expandedServices);
  } else {
    // Yeni adisyon modu
    setIsEditing(false);
    setSelectedBillId(null);
    setBillForm({
      date: getCurrentDate(),
      customerId: "",
      employeeId: "",
      services: [],
      price: "",
      discount: "",
      paymentType: "Nakit",
      status: "active",
      selectedCustomer: null,
      selectedEmployee: null
    });
    setSelectedServices([]);
    setApplyDiscount(false);
    setDiscountAmount(0);
  }
};

const handleDeleteBill = async (billId) => {
  try {
    const billRef = doc(db, "bills", billId);
    await updateDoc(billRef, { 
      status: "deleted",
      updatedAt: serverTimestamp()
    });
    
    await AuditLogger.log(
      user.uid,
      'delete',
      'bill',
      billId,
      { status: 'deleted' }
    );
    
    alert("Adisyon silindi.");
    fetchBills();
  } catch (error) {
    console.error("Silme hatası:", error);
    alert("Silme işlemi sırasında bir hata oluştu.");
  }
};

// Önce servisleri grupla ve seans sayılarını hesapla
const groupedServices = selectedServices.reduce((acc, service) => {
  const existingService = acc.find(s => s.id === service.id);
  if (existingService) {
    existingService.sessions++;
  } else {
    acc.push({
      id: service.id,
      name: service.name,
      price: service.price,
      sessions: 1
    });
  }
  return acc;
}, []);

const handleSaveBillData = async () => {
  const { isValid, errors } = validateBill(billForm);
  if (!isValid) {
    alert(Object.values(errors).join("\n"));
    return;
  }

try {
  if (selectedServices.length === 0) {
    alert("Lütfen en az bir işlem seçin.");
    return;
  }

  const totalPrice = selectedServices.reduce((sum, service) => sum + Number(service.price), 0);
  const calculatedDiscount = applyDiscount ? discountAmount : 0;
  const finalTotalPrice = totalPrice - calculatedDiscount;

  const billData = {
    customerId: billForm.customerId,
    employeeId: billForm.employeeId,
    paymentType: billForm.paymentType,
    date: billForm.date,
    price: totalPrice,
    discount: calculatedDiscount,
    totalPrice: finalTotalPrice,
    services: groupedServices,
    status: "active",
    userId: user.uid,
  };


    await withRateLimit(user.uid, async () => {
      if (isEditing) {
        await updateDoc(doc(db, "bills", selectedBillId), {
          ...billData,
          updatedAt: serverTimestamp(),
        });
      } else {
        await addDoc(collection(db, "bills"), {
          ...billData,
          createdAt: serverTimestamp(),
        });
      }
    });

    await fetchBills();
    setModalIsOpen(false);
    alert(isEditing ? "Adisyon güncellendi!" : "Yeni adisyon eklendi!");
  } catch (error) {
    console.error("İşlem hatası:", error);
    alert(`Bir hata oluştu: ${error.message}`);
  }
};

  const handleCustomerSelect = (customer) => {
    setBillForm(prev => ({ ...prev, customerId: customer.id }));
  };

  const handleEmployeeSelect = (employee) => {
    setBillForm(prev => ({ ...prev, employeeId: employee.id }));
  };

const handleServicesSelect = (services) => {
  const totalPrice = services.reduce((total, service) => total + Number(service.price), 0);
  setBillForm(prev => ({
    ...prev,
    services: services,
    price: totalPrice // Form'daki price değerini güncelliyoruz
  }));
  setSelectedServices(services);
};

  // Modified groupBillsByDate function
  const groupBillsByDate = (bills) => {
    return bills.reduce((groups, bill) => {
      const date = bill.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(bill);
      return groups;
    }, {});
  };


// COSTS PART

const [costs, setCosts] = useState([]);
const [costModalIsOpen, setCostModalIsOpen] = useState(false);
const [selectedCostId, setSelectedCostId] = useState(null);
const [costForm, setCostForm] = useState({
  date: new Date().toISOString().split('T')[0],
  description: "",
  price: "",
  paymentType: "Nakit",
  categoryId: "",
  categoryName: "",
  subCategory: ""
});

// Fetch costs fonksiyonu
const fetchCosts = async () => {
  try {
    const costsRef = collection(db, "costs");
    let costsQuery;

    if (user.role === 'cashier') {
      const today = getCurrentDate();
      costsQuery = query(
        costsRef,
        where("status", "==", "active"),  // status kontrolü eklendi
        where("date", "==", today)
      );
    } else {
      costsQuery = query(costsRef, where("status", "==", "active")); // status kontrolü eklendi
    }

    const costsSnapshot = await getDocs(costsQuery);
    const costsData = costsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setCosts(costsData);
  } catch (error) {
    console.error("Giderler getirilirken hata oluştu:", error);
  }
};

const groupCostsByDate = (costs) => {
  return costs.reduce((groups, cost) => {
    const date = cost.date;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(cost);
    return groups;
  }, {});
};

const handleDeleteCost = async (costId) => {
  try {
    const costRef = doc(db, "costs", costId);
    await deleteDoc(costRef);
    
    await AuditLogger.log(
      user.uid,
      'delete',
      'cost',
      costId,
      { status: 'deleted' }
    );
    
    alert("Gider silindi.");
    fetchCosts();
  } catch (error) {
    console.error("Silme hatası:", error);
    alert("Silme işlemi sırasında bir hata oluştu.");
  }
};

const handleOpenCostModal = (cost = null) => {
  setCostModalIsOpen(true);
  if (cost) {
    // Düzenleme modu
    setSelectedCostId(cost.id);
    setCostForm({
      date: cost.date,
      description: cost.description,
      price: cost.price,
      paymentType: cost.paymentType || "Nakit",
      categoryId: cost.categoryId || "",
      categoryName: cost.categoryName || "",
      subCategory: cost.subCategory || ""
    });
  } else {
    // Yeni gider modu
    setSelectedCostId(null);
    setCostForm({
      date: getCurrentDate(),
      description: "",
      price: "",
      paymentType: "Nakit",
      categoryId: "",
      categoryName: "",
      subCategory: ""
    });
  }
};

const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  return str.trim().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};

const handleSaveCost = async () => {
  try {
    if (!costForm.price) {
      alert("Lütfen tutar alanını doldurun.");
      return;
    }

    // Açıklamayı formatla
    const costData = {
      ...costForm,
      description: costForm.description ? capitalizeFirstLetter(costForm.description) : "",
      price: Number(costForm.price),
      status: "active",
      userId: user.uid
    };

    if (selectedCostId) {
      await updateDoc(doc(db, "costs", selectedCostId), {
        ...costData,
        updatedAt: serverTimestamp()
      });
    } else {
      await addDoc(collection(db, "costs"), {
        ...costData,
        createdAt: serverTimestamp()
      });
    }

    await fetchCosts();
    setCostModalIsOpen(false);
    alert(selectedCostId ? "Gider güncellendi!" : "Yeni gider eklendi!");
  } catch (error) {
    console.error("İşlem hatası:", error);
    alert(`Bir hata oluştu: ${error.message}`);
  }
};

const [searchTerm, setSearchTerm] = useState("");

// Arama fonksiyonu
const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  setSearchTerm(e.target.value);
  
  if (!searchValue) {
    fetchBills(); // Arama terimi yoksa tüm adisyonları getir
    return;
  }

  // Mevcut adisyonlar içinde arama yap
  const filtered = bills.filter((bill) => {
    // Müşteri adı-soyadı araması
    const customerFullName = `${bill.customerName}`.toLowerCase();
    const customerReversed = customerFullName.split(' ').reverse().join(' ');
    
    // Çalışan adı-soyadı araması
    const employeeFullName = `${bill.employeeName}`.toLowerCase();
    const employeeReversed = employeeFullName.split(' ').reverse().join(' ');
    
    // İşlem adları araması
    const servicesStr = bill.serviceNames.toLowerCase();
    
    // Fiyat araması (hem tam fiyat hem de kısmi eşleşme için)
    const priceStr = bill.totalPrice.toString();
    const isPriceMatch = priceStr.includes(searchValue);

    return (
      customerFullName.includes(searchValue) ||
      customerReversed.includes(searchValue) ||
      employeeFullName.includes(searchValue) ||
      employeeReversed.includes(searchValue) ||
      servicesStr.includes(searchValue) ||
      isPriceMatch
    );
  });

  // Filtrelenmiş sonuçları state'e ata
  setBills(filtered);
};

// Function to create a bill from appointment data
const createBillFromAppointment = (appointmentData) => {
  if (!appointmentData) return;
  
  const { customerId, employeeId, services: appointmentServices } = appointmentData;
  
  // Additional safety check
  if (customers.length === 0 || employees.length === 0) {
    console.error("Customer or employee data not loaded yet, aborting bill creation");
    alert("Veri yükleme hatası. Lütfen sayfayı yenileyip tekrar deneyin.");
    return;
  }
  
  // Find selected customer and employee
  const selectedCustomer = customers.find(c => c.id === customerId);
  const selectedEmployee = employees.find(e => e.id === employeeId);
  
  if (!selectedCustomer || !selectedEmployee) {
    console.error("Could not find customer or employee for appointment", {
      customerId,
      employeeId,
      foundCustomer: !!selectedCustomer,
      foundEmployee: !!selectedEmployee,
      availableCustomerIds: customers.map(c => c.id),
      availableEmployeeIds: employees.map(e => e.id)
    });
    
    alert("Müşteri veya çalışan bilgileri bulunamadı. Lütfen manuel olarak yeni adisyon oluşturun.");
    return; // Stop execution if we can't find customer or employee
  }
  
  // Prepare services for bill
  let billServices = [];
  if (appointmentServices && Array.isArray(appointmentServices)) {
    // Expand services based on sessions count
    billServices = appointmentServices.reduce((acc, service) => {
      const serviceDetails = services.find(s => s.id === service.id);
      if (serviceDetails) {
        // Create an array of services based on sessions count
        const servicesArray = Array(service.sessions || 1).fill({
          id: service.id,
          name: service.name || serviceDetails.name,
          price: service.price || serviceDetails.price
        });
        return [...acc, ...servicesArray];
      }
      return acc;
    }, []);
  }
  
  if (billServices.length === 0) {
    console.error("No services found for appointment");
    alert("Randevu için işlem bilgileri bulunamadı. Lütfen manuel olarak işlem ekleyin.");
  }
  
  // Set up bill form and open modal
  const newBillForm = {
    date: getCurrentDate(),
    customerId: customerId,
    employeeId: employeeId,
    price: "",
    discount: "",
    paymentType: "Nakit",
    status: "active",
    selectedCustomer,
    selectedEmployee
  };

  setBillForm(newBillForm);
  
  // First set the form, then set the services to make sure the form is updated
  setTimeout(() => {
    setSelectedServices(billServices);
    setApplyDiscount(false);
    setDiscountAmount(0);
    setIsEditing(false);
    setSelectedBillId(null);
    setModalIsOpen(true);
  }, 100);
};

return (

<div className="p-6">
    {/* Ödeme Özeti */}
    <PaymentSummary bills={bills} costs={costs} />
    
    {/* Ayırıcı çizgi */}
    <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-6"></div>

    {/* Arama ve Butonlar - Mobile Responsive */}
    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-6">
      {/* Arama Çubuğu */}
      <div className="relative w-full sm:max-w-2xl">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
        <input
          type="text"
          placeholder="Müşteri, çalışan, işlem veya fiyat ara..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      {/* Butonlar */}
      <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 w-full sm:w-auto">
        <button 
          onClick={() => handleOpenModal()}
          className="flex items-center justify-center px-4 py-2.5 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300 w-full sm:w-auto"
        >
          + Adisyon Ekle
        </button>

        <button 
          onClick={() => handleOpenCostModal()}
          className="flex items-center justify-center px-4 py-2.5 bg-gradient-to-r from-red-500 to-pink-500 text-white rounded-md hover:from-red-600 hover:to-pink-600 transition-all duration-300 w-full sm:w-auto"
        >
          + Gider Ekle
        </button>
      </div>
    </div>

    {/* Bugünün Adisyonları */}
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Bugünün Adisyonları
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {bills
          .filter(bill => bill.date === today)
          .map((bill) => (
            <BillCard
              key={bill.id}
              bill={bill}
              onEdit={handleOpenModal}
              onDelete={handleDeleteBill}
            />
          ))}
        {bills.filter(bill => bill.date === today).length === 0 && (
          <p className="text-gray-500 col-span-full text-center py-4">
            Bugün için henüz adisyon bulunmuyor.
          </p>
        )}
      </div>
    </div>

{/* Bugünün Giderleri */}
<div className="mb-8">
  <h2 className="text-xl font-semibold mb-4 text-gray-800">
    Bugünün Giderleri
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {costs
      .filter(cost => cost.date === today)
      .map((cost) => (
        <CostCard
          key={cost.id}
          cost={cost}
          onEdit={handleOpenCostModal}
          onDelete={handleDeleteCost}
        />
      ))}
    {costs.filter(cost => cost.date === today).length === 0 && (
      <p className="text-gray-500 col-span-full text-center py-4">
        Bugün için henüz gider bulunmuyor.
      </p>
    )}
  </div>
</div>

    {/* Geçmiş Adisyonlar - Sadece admin rolü için */}
    {user.role === 'admin' && bills.some(bill => {
      const dateRange = getDateRange();
      return dateRange.includes(bill.date) && bill.date !== today;
    }) && (
      <>
        <div className="border-t border-gray-200 my-8"></div>
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Geçmiş Adisyonlar
          </h2>
          {Object.entries(groupBillsByDate(bills.filter(bill => {
            const dateRange = getDateRange();
            return dateRange.includes(bill.date) && bill.date !== today;
          })))
            .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
            .map(([date, dateBills]) => (
              <div key={date} className="mb-8">
                <h3 className="text-lg font-medium mb-4 text-gray-700">
                  {new Date(date).toLocaleDateString('tr-TR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {dateBills.map((bill) => (
                    <BillCard
                      key={bill.id}
                      bill={bill}
                      onEdit={handleOpenModal}
                      onDelete={handleDeleteBill}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </>
    )}

{/* Geçmiş Giderler - Sadece admin rolü için */}
{user.role === 'admin' && costs.some(cost => {
  const dateRange = getDateRange();
  return dateRange.includes(cost.date) && cost.date !== today;
}) && (
  <>
    <div className="border-t border-gray-200 my-8"></div>
    <div>
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Geçmiş Giderler
      </h2>
      {Object.entries(groupCostsByDate(costs.filter(cost => {
        const dateRange = getDateRange();
        return dateRange.includes(cost.date) && cost.date !== today;
      })))
        .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
        .map(([date, dateCosts]) => (
          <div key={date} className="mb-8">
            <h3 className="text-lg font-medium mb-4 text-gray-700">
              {new Date(date).toLocaleDateString('tr-TR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {dateCosts.map((cost) => (
                <CostCard
                  key={cost.id}
                  cost={cost}
                  onEdit={handleOpenCostModal}
                  onDelete={handleDeleteCost}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  </>
)}

    {/* BillModal */}
    <BillModal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      billForm={billForm}
      setBillForm={setBillForm}
      onBillFormChange={(field, value) => setBillForm(prev => ({ ...prev, [field]: value }))}
      onSave={handleSaveBillData}
      isEditing={isEditing}
      applyDiscount={applyDiscount}
      setApplyDiscount={setApplyDiscount}
      discountAmount={discountAmount}
      setDiscountAmount={setDiscountAmount}
      selectedServices={selectedServices}
      setSelectedServices={setSelectedServices}
      customers={customers}
      employees={employees}
      services={services}
    />

{/* CostModal */}
<CostModal
  isOpen={costModalIsOpen}
  onClose={() => setCostModalIsOpen(false)}
  costForm={costForm}
  setCostForm={setCostForm}
  onSave={handleSaveCost}
  isEditing={!!selectedCostId}
/>
  </div>
);
}

export default Home;