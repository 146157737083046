import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Home, Users, Briefcase, FileText, PieChart, LogOut, TrendingUp, Calendar, Menu, X, Settings } from 'lucide-react';

const Navigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close mobile menu when navigation occurs
  const handleNavigation = () => {
    setIsMenuOpen(false);
  };

  const menuItems = [
    { 
      path: '/home', 
      name: 'Ana Sayfa',
      icon: Home,
      allowedRoles: ['admin', 'cashier']
    },
    { 
      path: '/appointments', 
      name: 'Randevular',
      icon: Calendar,
      allowedRoles: ['admin', 'cashier'],
      isNew: true
    },
    { 
      path: '/customers', 
      name: 'Müşteriler',
      icon: Users,
      allowedRoles: ['admin', 'cashier']
    },
    { 
      path: '/employee', 
      name: 'Çalışanlar',
      icon: Briefcase,
      allowedRoles: ['admin']
    },
    { 
      path: '/services', 
      name: 'İşlemler',
      icon: FileText,
      allowedRoles: ['admin', 'cashier']
    },
    { 
      path: '/report', 
      name: 'Raporlar',
      icon: PieChart,
      allowedRoles: ['admin']
    },
    { 
      path: '/bests', 
      name: 'İstatistikler',
      icon: TrendingUp,
      allowedRoles: ['admin'],
    },
    { 
      path: '/customization', 
      name: 'Özelleştirme',
      icon: Settings,
      allowedRoles: ['admin', 'cashier'],
      isNew: true
    }
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Çıkış yapılırken hata oluştu:', error);
    }
  };

  const renderNavItems = (mobile = false) => {
    return menuItems.map((item) => {
      if (!item.allowedRoles.includes(user?.role)) {
        return null;
      }

      return (
        <NavLink
          key={`${item.path}${mobile ? '-mobile' : ''}`}
          to={item.path}
          onClick={handleNavigation}
          className={({ isActive }) =>
            `px-3 py-2 text-sm font-medium rounded-md transition-all duration-300
            flex items-center space-x-1 relative
            ${isActive 
              ? 'text-blue-600 bg-blue-50' 
              : 'text-gray-600 hover:text-blue-600 hover:bg-blue-50'
            } ${mobile ? 'w-full' : ''}`
          }
        >
          <item.icon className="h-4 w-4" />
          <span>{item.name}</span>
          {item.isNew && (
            <span className="absolute -top-1 -right-1 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
            </span>
          )}
        </NavLink>
      );
    });
  };

  return (
    <div className="bg-gradient-to-r from-cyan-500 to-blue-500 pb-1">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo Area */}
            <div className="flex-shrink-0 flex items-center">
              <span className="text-xl font-bold bg-gradient-to-r from-cyan-600 to-blue-600 bg-clip-text text-transparent">
                Adisyon Takip
              </span>
            </div>

            {/* Mobile Menu Button */}
            <div className="sm:hidden">
              <button 
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 
                hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>

            {/* Desktop Navigation Items */}
            <div className="hidden sm:flex sm:space-x-4">
              {renderNavItems()}
            </div>

            {/* Desktop Logout Button */}
            <div className="hidden sm:block">
              <button
                onClick={handleLogout}
                className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 
                         hover:text-red-600 hover:bg-red-50 rounded-md transition-all duration-300"
              >
                <LogOut className="h-4 w-4 mr-1" />
                <span>Çıkış Yap</span>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg border-t">
              {/* Mobile Navigation Items */}
              <div className="flex flex-col space-y-1">
                {renderNavItems(true)}
              </div>
              
              {/* Mobile Logout Button */}
              <button
                onClick={() => {
                  handleLogout();
                  setIsMenuOpen(false);
                }}
                className="w-full flex items-center justify-start px-3 py-2 text-sm font-medium text-gray-600 
                         hover:text-red-600 hover:bg-red-50 rounded-md transition-all duration-300 mt-2"
              >
                <LogOut className="h-4 w-4 mr-1" />
                <span>Çıkış Yap</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;