import React, { useState, useRef, useEffect } from "react";
import { Search, AlertCircle } from "lucide-react";

function SearchableDropdown({ items = [], onSelect, placeholder, value, icon: Icon, resetAfterSelect = false }) {
  const getFullName = (item) => {
    if (!item) return '';
    return `${item.name || ''} ${item.surname || ''}`.trim();
  };

  const [searchTerm, setSearchTerm] = useState(value ? getFullName(value) : "");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (value) {
      setSearchTerm(getFullName(value));
    }
  }, [value]);

  useEffect(() => {
    // Check if items are provided and valid
    if (!items || !Array.isArray(items)) {
      setError('Geçerli veri listesi bulunamadı');
    } else if (items.length === 0) {
      setError('Kullanılabilir veri bulunmamaktadır');
    } else {
      setError('');
    }
  }, [items]);

  // Alfabetik sıralama yaparak filtreleme
  const filteredItems = Array.isArray(items) ? items
    .filter((item) => {
      // If searchTerm is empty, show all items when dropdown is open
      if (searchTerm === "") return true;
      const fullName = getFullName(item);
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      return getFullName(a).localeCompare(getFullName(b), 'tr', { sensitivity: 'base' });
    }) : [];

  const handleSelect = (item) => {
    onSelect(item);
    if (resetAfterSelect) {
      setSearchTerm('');
    } else {
      setSearchTerm(getFullName(item));
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

const getDisplayText = (item) => {
  if (!item) return '';
  if (item.type === "Ürün") {
    return `${item.name} (Ürün)`;
  }
  return item.name || '';
};

return (
  <div ref={dropdownRef} className="relative w-full">
    {/* Debug */}
    {error && (
      <div className="mb-1 text-xs text-amber-600 flex items-center">
        <AlertCircle className="h-3 w-3 mr-1" />
        {error}
      </div>
    )}
    
    {/* Input Alanı */}
    <div className="relative">
      {Icon && (
        <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onFocus={() => setIsOpen(true)}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setIsOpen(true);
        }}
        onClick={() => setIsOpen(true)} // Ensure dropdown opens on click too
        className={`w-full px-3 py-2 border border-gray-300 rounded-md 
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
          transition-all ${Icon ? 'pl-10' : ''}`}
      />
      <Search 
        className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400 cursor-pointer" 
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown when search icon is clicked
      />
    </div>

    {/* Dropdown Listesi */}
    {isOpen && (
      <div className="absolute top-full left-0 right-0 mt-1 max-h-48 overflow-y-auto 
        bg-white border border-gray-200 rounded-md shadow-lg z-50">
        {filteredItems.length > 0 ? (
          <>
            {filteredItems.map((item) => (
              <div
                key={item.id}
                onClick={() => handleSelect(item)}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-50 
                  border-b border-gray-100 last:border-b-0
                  ${value && value.id === item.id ? 'bg-blue-50' : ''}
                  transition-colors duration-150`}
              >
                <div className="flex items-center gap-2">
                  <span className={value && value.id === item.id ? 'text-blue-600' : 'text-gray-700'}>
                    {getFullName(item)}
                  </span>
                  {item.type && (
                    <span className={`text-xs px-2 py-0.5 rounded-full ${
                      item.type === 'Ürün' 
                        ? 'bg-pink-100 text-pink-700'
                        : 'bg-blue-100 text-blue-700'
                    }`}>
                      {item.type}
                    </span>
                  )}
                </div>
              </div>
            ))}
            <div className="px-4 py-2 text-xs text-gray-500 text-right border-t border-gray-100">
              {filteredItems.length} sonuç gösteriliyor (toplam: {items.length})
            </div>
          </>
        ) : (
          <div className="px-4 py-2 text-center text-gray-500">
            Sonuç Bulunamadı
          </div>
        )}
      </div>
    )}
  </div>
);
}

export default SearchableDropdown;